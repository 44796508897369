import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, TextInput } from '@epcbuilder/lib/components';
import { Address, AddressResult, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { getAddress, getAddressRetrieve } from '@epcbuilder/lib/network/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const postcodeSearchSchema = yup.object().shape({
  postcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
});

const defaultValues: PostcodeSearch = {
  postcode: '',
};

const PostcodeSearchForm = ({ setAddress }: { setAddress: Dispatch<SetStateAction<Address | undefined>> }) => {
  const [results, setResults] = useState<AddressResult[]>([]);
  const [addressId, setAddressId] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<PostcodeSearch>({
    defaultValues,
    resolver: yupResolver(postcodeSearchSchema),
  });

  const onSubmit: SubmitHandler<PostcodeSearch> = async ({ postcode }: PostcodeSearch) => {
    try {
      const response = await getAddress({ postcode: postcode.trim() });
      setResults(response);
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<PostcodeSearch>(setError, errors);

      switch (detail) {
        case 'No addresses could be found':
        case 'Failed getting address':
          toast.error('There was an error, please check your postcode and try again', { toastId: 'query-error' });
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  const getFullAddress = useCallback(async () => {
    try {
      const address = await getAddressRetrieve({ id: addressId });
      setAddress(address[0]);
    } catch (error: unknown) {
      const { detail } = error as AxiosErrorData;

      switch (detail) {
        case 'No addresses could be found':
        case 'Failed getting address':
          toast.error('There was an error, please check your postcode and try again', { toastId: 'retrieve-error' });
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  }, [addressId, setAddress]);

  useEffect(() => {
    if (addressId) {
      getFullAddress();
    }
  }, [addressId, getFullAddress]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <>
      <form id="postcode-form" className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('postcode')}
          id="postcode"
          name="postcode"
          title="Your postcode"
          placeholder="Postcode"
          error={errors.postcode?.message}
        />
        {results.length > 0 && (
          <div className="border-primary overflow-y-hidden rounded-xl border-2">
            <div className="h-48 overflow-y-auto p-2">
              {results.map((item) => (
                <button
                  type="button"
                  key={item.id}
                  id={`${item.id}-address`}
                  className="hover:bg-primary hover:text-light cursor-pointer rounded-xl p-2 text-left"
                  onClick={() => setAddressId(item.id)}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        )}
        <Button id="postcode-submit" loading={isSubmitting} type="submit">
          Search
        </Button>
      </form>
      <button
        type="button"
        id="enter-manually-button"
        className="text-link mt-4 text-center"
        onClick={() => setAddress({ line1: '', line2: '', city: '', postalCode: '' } as Address)}
      >
        Or set manually
      </button>
    </>
  );
};

export default PostcodeSearchForm;

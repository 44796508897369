import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AuthLayout } from '@epcbuilder/lib/components';
import { ResetPasswordForm } from '@/components/reset-password';

const ResetPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Reset Password">
        <div className="mt-8 flex flex-row items-center">
          <p className="text-color-inverse">
            Remember your password?{' '}
            <Link id="login-link" className="text-link" to="/">
              Login
            </Link>
          </p>
        </div>
        <ResetPasswordForm />
      </AuthLayout>
    </>
  );
};

export default ResetPasswordPage;

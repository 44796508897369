import { User } from '@epcbuilder/lib/models/user';
import useSWR from 'swr';
import { getCompanyUsers } from '@/network/companies';

const useCompanyUsers = (id: number) => {
  const { data, error, mutate, isLoading } = useSWR<User[]>(`company-users`, async () => await getCompanyUsers({ id }));

  return {
    companyUsers: data,
    error,
    mutate,
    isLoading,
  };
};

export default useCompanyUsers;

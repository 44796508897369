import React from 'react';
import { Control, FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { Checkbox, FiltersContainer, Select, TextInput } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';
import { Filters } from '@/models/job';

const FiltersForm = <T extends FieldValues>({
  control,
  register,
  errors,
  reset,
}: {
  control: Control<T>;
  register: UseFormRegister<Filters>;
  errors: FieldErrors<Filters>;
  reset: () => void;
}) => {
  const isMobile = useMediaQuery(1023);
  return (
    <FiltersContainer id="properties-filters" reset={reset}>
      <div className="w-42 md:w-52">
        <Select
          multiple={true}
          control={control}
          id="epcCurrent"
          name={'currentEpcFilters' as Path<T>}
          title="Filter by current EPC rating"
          label={isMobile ? '' : 'Current EPC'}
          placeholder="Filter Current EPC"
          options={epcRatingOptions}
        />
      </div>
      <div className="w-42 md:w-52 md:self-end lg:w-96">
        <TextInput
          {...register('search')}
          id="search"
          name="search"
          title="your search query"
          placeholder="Search"
          error={errors.search?.message}
        />
      </div>
      <div className="flex h-full justify-between sm:flex-col lg:self-end">
        <div id="show-expired-checkbox">
          <Checkbox id="showExpired" {...register('showExpired')} label="Show Expired" />
        </div>
        <div id="show-completed-checkbox">
          <Checkbox id="showCompleted" {...register('showCompleted')} label="Show Completed" />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default FiltersForm;

import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, TextInput } from '@epcbuilder/lib/components';
import { ResendVerification } from '@epcbuilder/lib/models/auth';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { postResendVerificationToken } from '@/network/auth';

const resendVerificationSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
});

const ResendVerificationForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const defaultValues: ResendVerification = {
    email: decodeURIComponent(searchParams.get('email') || ''),
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ResendVerification>({
    defaultValues,
    resolver: yupResolver(resendVerificationSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ResendVerification> = async (data) => {
    try {
      await postResendVerificationToken({ email: data.email.trim() });
      toast.success('Verification email has been resent. Please check your email', {
        toastId: 'resend-verification-success',
      });
      navigate('/');
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<ResendVerification>(setError, errors);

      switch (detail) {
        case 'User is already confirmed':
          toast.error('User is already confirmed, redirecting to login page', { toastId: 'user-confirmed-error' });
          navigate('/');
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  return (
    <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email')}
        id="email"
        name="email"
        title="Your email address"
        placeholder="Email"
        error={errors?.email?.message}
        autoComplete="username"
      />
      <Button id="resend-verification-submit" loading={isSubmitting} type="submit">
        Resend Verification Email
      </Button>
    </form>
  );
};

export default ResendVerificationForm;

import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineAssignment, MdOutlinePlace } from 'react-icons/md';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import { JobStatus, JobStatusStrings } from '@epcbuilder/lib/models/jobs';
import { capitalize } from '@epcbuilder/lib/utils';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import { EpcWizardSteps } from '@/models/job';
import EnergyAssessmentStep from './steps/energy-assessment';
import EpcRatingStep from './steps/epc-rating';
import ImprovementPlanStep from './steps/improvement-plan';
import InstallationStep from './steps/install';
import JobComplete from './steps/job-complete';
import EpcWizardStepper from './Stepper';

const mapJobStatusToEpcWizardStep = (jobStatus?: JobStatus): EpcWizardSteps => {
  switch (jobStatus) {
    case JobStatus.NEW:
    case JobStatus.PENDING:
      return EpcWizardSteps.EPC_RATING;
    case JobStatus.SURVEY:
      return EpcWizardSteps.ENERGY_ASSESSMENT;
    case JobStatus.REPORT:
      return EpcWizardSteps.IMPROVEMENT_PLAN;
    case JobStatus.INSTALLATION:
      return EpcWizardSteps.INSTALL;
    case JobStatus.COMPLETE:
      return EpcWizardSteps.JOB_COMPLETE;
    default:
      return EpcWizardSteps.EPC_RATING;
  }
};

const EpcWizard = () => {
  const { property } = usePropertyContext();
  const { job } = useJobContext();

  const [active, setActive] = useState<EpcWizardSteps>(mapJobStatusToEpcWizardStep(job?.jobStatusID));

  useEffect(() => {
    if (job) {
      setActive(mapJobStatusToEpcWizardStep(job.jobStatusID));
    }
  }, [job]);

  const mapJobStatusToEpcWizardSteps = (
    jobStatus: JobStatus
  ): { value: EpcWizardSteps; locked: boolean; completed: boolean }[] => {
    const numericValues = Object.keys(EpcWizardSteps)
      .filter((key) => isNaN(Number(key))) // Filter out keys that can be converted to numbers
      .map((key) => EpcWizardSteps[key as keyof typeof EpcWizardSteps]);

    const steps = numericValues.map((value) => ({
      value,
      locked: false,
      completed: false,
    }));

    switch (jobStatus) {
      case JobStatus.NEW:
      case JobStatus.PENDING:
        return steps.map((step) => ({
          ...step,
          locked: step.value > EpcWizardSteps.EPC_RATING,
        }));
      case JobStatus.SURVEY:
        return steps.map((step) => ({
          ...step,
          completed: step.value < EpcWizardSteps.ENERGY_ASSESSMENT,
          locked: step.value > EpcWizardSteps.ENERGY_ASSESSMENT,
        }));
      case JobStatus.REPORT:
        return steps.map((step) => ({
          ...step,
          completed: step.value < EpcWizardSteps.IMPROVEMENT_PLAN,
          locked: step.value > EpcWizardSteps.IMPROVEMENT_PLAN,
        }));
      case JobStatus.INSTALLATION:
        return steps.map((step) => ({
          ...step,
          completed: step.value < EpcWizardSteps.INSTALL,
          locked: step.value > EpcWizardSteps.INSTALL,
        }));
      case JobStatus.COMPLETE:
        return steps.map((step) => ({
          ...step,
          completed: step.value <= EpcWizardSteps.JOB_COMPLETE,
        }));
    }

    return steps;
  };

  const renderStep = useCallback(() => {
    switch (active) {
      case EpcWizardSteps.EPC_RATING:
        return <EpcRatingStep setActive={setActive} />;
      case EpcWizardSteps.ENERGY_ASSESSMENT:
        return <EnergyAssessmentStep />;
      case EpcWizardSteps.IMPROVEMENT_PLAN:
        return <ImprovementPlanStep setActive={setActive} />;
      case EpcWizardSteps.INSTALL:
        return <InstallationStep />;
      case EpcWizardSteps.JOB_COMPLETE:
        return <JobComplete job={job} />;
      default: {
        const _exhaustiveCheck: never = active;
        return _exhaustiveCheck;
      }
    }
  }, [active, job]);

  if (!job) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 lg:flex-row">
        <div
          className="border-neutral dark:bg-dark-dark dark:border-neutral-dar flex flex-row items-center gap-2 overflow-hidden rounded-[22px] border-2 bg-white p-2 lg:flex-1"
          id="address-display"
        >
          <MdOutlinePlace size={20} className="text-blue" />
          <p>{`${property?.addressLine1}, ${property?.addressLine2 && `${property?.addressLine2}`}`}</p>
        </div>
        <div
          className="border-neutral dark:bg-dark-dark dark:border-neutral-dark flex flex-row items-center gap-2 overflow-hidden rounded-[22px] border-2 bg-white p-2 lg:flex-1"
          id="status-display"
        >
          <MdOutlineAssignment size={20} className="text-blue" />
          <p>{`Status: ${capitalize(JobStatusStrings[job?.jobStatusID])}`}</p>
        </div>
      </div>
      <EpcWizardStepper
        epcWizardSteps={mapJobStatusToEpcWizardSteps(job.jobStatusID)}
        active={active}
        setActive={setActive}
      />
      {renderStep()}
    </div>
  );
};

export default EpcWizard;

import React from 'react';
import { Loading } from '@epcbuilder/lib/components';
import { CompanyJoinRequest } from '@epcbuilder/lib/models/companies';
import CompanyRequestRow from './CompanyRequestsRow';

const CompanyRequestsTable = ({
  isLoading,
  requests,
  refetch,
}: {
  isLoading: boolean;
  requests: CompanyJoinRequest[] | undefined;
  refetch: () => void;
}) => {
  if (isLoading || !requests) {
    return <Loading id="company-users-loading" />;
  }

  if (requests.length === 0) {
    return (
      <p id="properties-count-empty" className="my-8 flex items-center justify-center">
        You have no join requests currently
      </p>
    );
  }

  return (
    <div id="requests-table">
      {requests.map((request) => (
        <CompanyRequestRow key={request.id} request={request} refetch={refetch} />
      ))}
    </div>
  );
};

export default CompanyRequestsTable;

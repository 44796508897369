import { addDays, addMonths, isWeekend, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const formatUtcDatetime = (date: Date) => zonedTimeToUtc(date, 'UTC').toISOString();

export const addWeekdayDays = (startDate: Date, daysToAdd: number) => {
  let currentDate = startDate;

  while (daysToAdd > 0) {
    currentDate = addDays(currentDate, 1);
    daysToAdd -= isWeekend(currentDate) ? 0 : 1;
  }

  return currentDate;
};

export const subtractWeekdayDays = (startDate: Date, daysToSubtract: number) => {
  let currentDate = startDate;

  while (daysToSubtract > 0) {
    currentDate = subDays(currentDate, 1);
    daysToSubtract -= isWeekend(currentDate) ? 0 : 1;
  }

  return currentDate;
};

export const addWeekdayMonths = (startDate: Date, monthsToAdd: number) => {
  let currentDate = addMonths(startDate, monthsToAdd);

  while (isWeekend(currentDate)) {
    currentDate = addDays(currentDate, 1);
  }

  return currentDate;
};

export type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
export const DaysOfWeek: Day[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

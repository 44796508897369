import React from 'react';
import { JobSurvey, SurveyStatus } from '@epcbuilder/lib/models/jobs';
import { EligibilityStatus } from '@/models/job';
import GenericNextStepsCard from '../GenericNextStepsCard';

const getMessage = (surveyStatusID?: number, eligibility?: boolean): string => {
  switch (surveyStatusID) {
    case SurveyStatus.SURVEY_CANCELLED:
      return 'Please choose an available time slot and we will be in touch to take the payment and secure your appointment.';
    case SurveyStatus.REPORT_COMPLETED:
      return 'Your report has been completed and is ready to view and download on step 3.';
    case SurveyStatus.REPORT_IN_PROGRESS:
      return 'Your report is in progress. Look out for an email notifying you that your Improvement Plan is ready.';
    case SurveyStatus.SURVEY_COMPLETED:
      return 'Your survey has been completed. Look out for an email notifying you that your Improvement Plan is ready.';
    case SurveyStatus.SURVEY_BOOKED:
      return 'Your booking has been confirmed and a survey confirmation email has been sent. If you would like to change your booking, please cancel your current appointment first.';
    case SurveyStatus.SURVEY_REQUESTED:
      return 'We have received your requested booking slot and will be in touch to confirm your booking.';
    default:
      return eligibility
        ? 'You are eligible for free home improvement upgrades. Please choose an available time slot and we will be in touch to take the payment and secure your appointment.'
        : 'Please choose an available time slot and we will be in touch to take the payment and secure your appointment.';
  }
};

const NextStepsCard = ({
  jobSurvey,
  jobEligibilityStatus,
}: {
  jobSurvey?: JobSurvey;
  jobEligibilityStatus?: EligibilityStatus;
}) => {
  const message = getMessage(jobSurvey?.surveyStatusID, jobEligibilityStatus?.eligibility);

  return (
    <GenericNextStepsCard>
      <p className="font-bold">{message}</p>
    </GenericNextStepsCard>
  );
};

export default NextStepsCard;

import React, { useContext, useEffect, useState } from 'react';
import { FaPeopleGroup } from 'react-icons/fa6';
import { MdHistory, MdLogout, MdOutlinePhone, MdPerson } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { ContactModal } from './ContactModal';

enum MenuItems {
  PROPERTIES = 'properties',
  PROFILE = 'profile',
  JOBHISTORY = 'job-history',
  COMPANY = 'company',
}

interface MenuItemProps {
  id: MenuItems;
  icon: JSX.Element;
  text: string;
  href: string;
  active: MenuItems;
  newItem?: boolean;
  setActive: React.Dispatch<React.SetStateAction<MenuItems>>;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ id, icon, text, href, active, newItem, setActive, onClick }) => {
  const baseClassNames = 'flex flex-row items-center h-12 rounded-xl px-3 cursor-pointer border-2';
  const activeClassNames =
    active === id
      ? 'border-primary bg-primary text-light'
      : 'text-color hover:border-primary border-transparent bg-transparent';

  return (
    <Link
      to={href}
      id={`sidebar-${id}`}
      className={`${baseClassNames} ${newItem ? 'navbar-item-new' : ''} ${activeClassNames}`}
      onClick={() => {
        setActive(id);
        onClick?.();
      }}
    >
      {icon}
      <p id={active === id ? 'sidebar-active' : undefined} className="ml-4 hidden group-hover:block lg:block">
        {text}
      </p>
    </Link>
  );
};

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatchLogout } = useContext(AuthContext);
  const [active, setActive] = useState<MenuItems>(location.pathname.split('/')[1] as MenuItems);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSecondarySidebar, setShowSecondarySidebar] = useState(false);
  const [isSmallHeight, setIsSmallHeight] = useState(window.innerHeight < 450);
  const isMobile = useMediaQuery(640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallHeight(window.innerHeight < 450);
      if (window.innerHeight >= 450) setShowSecondarySidebar(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
      const sidebarElement = document.getElementById('secondary-sidebar');
      const profileIconElement = document.getElementById('sidebar-profile-icon');
      const target = event.target as Node;

      if (!sidebarElement?.contains(target) && !profileIconElement?.contains(target)) {
        setShowSecondarySidebar(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileIconClick = () => setShowSecondarySidebar(!showSecondarySidebar);

  return (
    <>
      <div
        id="sidebar"
        className={`max-w-18 bg-color border-neutral group fixed left-0 z-10 hidden h-screen w-16 border-r-2 pt-[3rem] shadow hover:w-48 sm:block lg:w-48 lg:pt-24`}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-1 flex-col gap-2 px-2 py-4">
            <MenuItem
              id={MenuItems.PROPERTIES}
              href="/properties"
              icon={<PiHouseSimpleBold size={20} />}
              text="Properties"
              active={active}
              setActive={setActive}
            />
            <MenuItem
              id={MenuItems.JOBHISTORY}
              href="/job-history"
              icon={<MdHistory size={20} />}
              text="Job History"
              active={active}
              setActive={setActive}
            />
            <MenuItem
              id={MenuItems.COMPANY}
              href="/company"
              icon={<FaPeopleGroup size={20} />}
              text="Company"
              active={active}
              newItem
              setActive={setActive}
            />
          </div>
          <div className="flex flex-col gap-2 px-2 py-4">
            {isSmallHeight ? (
              <button
                type="button"
                id="sidebar-profile-icon"
                className="text-color hover:border-primary flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 border-transparent bg-transparent px-3"
                onClick={handleProfileIconClick}
              >
                <MdPerson size={20} />
                <div className="ml-4 hidden group-hover:block lg:block">Profile</div>
              </button>
            ) : (
              <>
                <button
                  type="button"
                  id="sidebar-contact"
                  className="text-color hover:border-primary flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 border-transparent bg-transparent px-3"
                  onClick={() => setShowContactModal(true)}
                >
                  <MdOutlinePhone size={20} />
                  <div className="ml-4 hidden group-hover:block lg:block">Contact Us</div>
                </button>
                <MenuItem
                  id={MenuItems.PROFILE}
                  href="/profile"
                  icon={<MdPerson size={20} />}
                  text="Profile"
                  active={active}
                  setActive={setActive}
                />
                <button
                  type="button"
                  id="sidebar-logout"
                  className="text-color hover:border-primary flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 border-transparent bg-transparent px-3"
                  onClick={() => {
                    dispatchLogout();
                    navigate('/');
                  }}
                >
                  <MdLogout size={20} />
                  <div className="ml-4 hidden group-hover:block lg:block">Logout</div>
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {isSmallHeight && showSecondarySidebar && !isMobile && (
        <div
          id="secondary-sidebar"
          className="bg-color border-neutral fixed left-[12rem] top-0 z-10 h-screen w-48 border-r-2 pt-24 shadow-lg"
        >
          <div className="flex flex-col gap-2 px-2 py-4">
            <button
              type="button"
              id="secondary-sidebar-contact-us"
              className="text-color flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 border-transparent bg-transparent px-3"
              onClick={() => {
                setShowContactModal(true);
                setShowSecondarySidebar(false);
              }}
            >
              <MdOutlinePhone size={20} />
              <div className="ml-4 block">Contact Us</div>
            </button>
            <button
              type="button"
              id="secondary-sidebar-profile"
              className="text-color flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 border-transparent bg-transparent px-3"
              onClick={() => {
                handleProfileIconClick();
                navigate('/profile');
                setShowSecondarySidebar(false);
              }}
            >
              <MdPerson size={20} />
              <div className="ml-4 block">Profile</div>
            </button>
            <button
              type="button"
              id="secondary-sidebar-logout"
              className="text-color flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 border-transparent bg-transparent px-3"
              onClick={() => {
                dispatchLogout();
                navigate('/');
                setShowSecondarySidebar(false);
              }}
            >
              <MdLogout size={20} />
              <div className="ml-4 block">Logout</div>
            </button>
          </div>
        </div>
      )}

      {showContactModal && <ContactModal setShowContactModal={setShowContactModal} />}
    </>
  );
};

export default SideBar;

import React, { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Checkbox, TextInput } from '@epcbuilder/lib/components';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import { Login } from '@epcbuilder/lib/models/auth';
import { Token } from '@epcbuilder/lib/models/authContext';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as jose from 'jose';
import * as yup from 'yup';
import { postLogin } from '@/network/auth';

const loginSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  password: yup.string().required('Password must not be empty'),
  rememberMe: yup.bool().required(),
});

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const { dispatchLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  const defaultValues: Login = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getValues,
  } = useForm<Login>({
    defaultValues,
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Login> = async (data) => {
    try {
      const redirect = searchParams.get('redirect');
      const response = await postLogin({
        email: data.email.trim(),
        password: data.password,
        rememberMe: data.rememberMe,
      });
      if (!response) throw Error();
      const { token, refreshToken } = response.data;
      const payload = jose.decodeJwt(token) as Token;
      dispatchLogin({ token, refreshToken, rememberMe: getValues('rememberMe'), userId: payload.ID });
      toast.success('Login successful', { toastId: 'login-success' });
      navigate(redirect || '/properties');
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<Login>(setError, errors);

      switch (detail) {
        case 'Account is not confirmed':
          toast.error('Account is not confirmed, redirecting to request new verification email.', {
            toastId: 'login-unconfirmed-error',
          });
          navigate(`/resend-verification?email=${encodeURIComponent(data.email.trim())}`);
          break;
        case 'User is deleted':
          toast.error(
            'Account has been deleted. Please contact support on 0800 058 4140 if you want to reactivate the account.',
            { toastId: 'login-deleted-error' }
          );
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  return (
    <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email')}
        id="email"
        name="email"
        title="Your email address"
        placeholder="Email"
        error={errors.email?.message}
        autoComplete="username"
      />
      <TextInput
        {...register('password')}
        id="password"
        name="password"
        title="Your password"
        placeholder="Password"
        error={errors.password?.message}
        type="password"
        autoComplete="current-password"
      />
      <div className="flex flex-row flex-wrap justify-between gap-2">
        <div id="remember-me-checkbox" className="text-color-inverse flex flex-row items-center gap-2">
          <Checkbox
            id="rememberMe"
            {...register('rememberMe')}
            label="Remember me"
            labelclassname="text-color-inverse"
          />
        </div>
        <Link id="forgot-password-link" className="text-link" to="forgot-password">
          Forgot password
        </Link>
      </div>
      <Button id="login-submit" loading={isSubmitting} type="submit">
        Login
      </Button>
    </form>
  );
};

export default LoginForm;

import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Card } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { Job } from '@epcbuilder/lib/models/jobs';

const JobCompleteConfetti = () => {
  const isMobile = useMediaQuery(1023);
  const confettiCount = isMobile ? 50 : 100;
  const [shouldMakeConfettiBits, setShouldMakeConfettiBits] = useState(true);

  useEffect(() => {
    setTimeout(() => setShouldMakeConfettiBits(false), 5000);
  }, []);

  return (
    <Confetti
      opacity={0.9}
      numberOfPieces={shouldMakeConfettiBits ? confettiCount : 0}
      width={isMobile ? 320 : 659}
      height={isMobile ? 580 : 540}
      initialVelocityY={-5}
    />
  );
};

const JobComplete = ({ job }: { job?: Job }) => {
  const isMobile = useMediaQuery(1023);

  return (
    <div className="grid justify-center">
      <Card className="lg:max-w-none lg:p-8">
        <JobCompleteConfetti />
        <div className="flex flex-col items-center gap-4 lg:gap-8">
          <div className="flex w-full flex-col items-center gap-4 lg:flex-row-reverse lg:justify-around">
            <div className="flex max-w-96 flex-col text-center">
              <p className="text-lg font-bold lg:text-xl">
                Congratulations on improving the energy efficiency of your property.
              </p>
            </div>
            <div className="relative size-[140px] lg:size-[195px]">
              <img src="/images/congratulations.png" alt="Congratulations" className="object-contain" />
            </div>
          </div>
          <div className="bg-blue dark:bg-primary-dark flex w-full flex-row justify-around gap-4 rounded-[16px] p-2 lg:py-4">
            <div className="flex flex-col items-center lg:flex-row lg:gap-2">
              <p className="font-bold">{isMobile ? 'Previous EPC' : 'Your EPC rating was:'}</p>
              <p className="text-error font-extrabold">{job?.actualEpc}</p>
            </div>
            <div className="flex flex-col items-center lg:flex-row lg:gap-2">
              <p className="font-bold">{isMobile ? 'New EPC' : 'Your EPC rating is now:'}</p>
              <p className="text-primary font-extrabold">{job?.newEpc}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 text-left lg:px-4">
            <p className="font-sm">Great news - your property is now more energy efficient!</p>
            <p className="font-sm">We hope you soon see the benefits of these improvements including:</p>
            <ul className="ml-2 list-inside list-disc">
              <li>Lower energy bills</li>
              <li>Reduced carbon emissions</li>
              <li>A more comfortable living environment</li>
            </ul>
            <p className="font-sm">Thank you for using EPC Builder.</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobComplete;

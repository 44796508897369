import { Property } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getProperty } from '@/network/properties';

const useProperty = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<Property>(id ? `property-data-${id}` : null, async () =>
    id ? await getProperty({ id }) : null
  );

  return {
    property: data,
    error,
    mutate,
    isLoading,
  };
};

export default useProperty;

import { JobSurvey } from '@epcbuilder/lib/models/jobs';
import useSWR from 'swr';
import { getJobSurvey } from '@/network/jobs';

const useJobSurvey = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<JobSurvey>(id ? `job-survey-${id}` : null, async () =>
    id ? await getJobSurvey({ id }) : null
  );

  return {
    jobSurvey: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobSurvey;

import React, { useCallback, useState } from 'react';
import { BiShow } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IconButton } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { Company } from '@epcbuilder/lib/models/companies';
import { Property } from '@epcbuilder/lib/models/properties';
import { deleteProperty } from '@/network/properties';
import { PropertyAddressData, PropertyEpcData, PropertyOwnerInitialsData, PropertyStatusData } from './PropertyData';

const PropertyRow = ({
  property,
  company,
  refetch,
}: {
  property: Property;
  company: Company | undefined;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const navigateToProperty = () => {
    navigate(`/properties/${property.id}`);
  };
  const navigateToProperyKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      navigateToProperty();
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleDeleteProperty = useCallback(async () => {
    try {
      await deleteProperty({ id: property.id });
      toast.success('Property successfully deleted', { toastId: 'delete-success' });
      refetch();
    } catch (error: unknown) {
      toast.error('There was a problem deleting Property. Please try again.');
    }
  }, [refetch, property]);

  return (
    <>
      <div
        id={property.id}
        className="border-neutral hover:bg-neutral-dark/10 flex min-h-20 w-full flex-row items-center justify-between border-b-2 px-4"
      >
        <div
          tabIndex={0}
          className="flex size-full min-h-20 flex-row gap-2"
          onClick={navigateToProperty}
          onKeyDown={navigateToProperyKeyPress}
          role="button"
        >
          {company && company.id > 0 && (
            <PropertyOwnerInitialsData className="ml-[-16px]" property={property}></PropertyOwnerInitialsData>
          )}
          <div className="flex flex-1 flex-row gap-2 p-2">
            <PropertyAddressData property={property} className="border-primary md:border-r-2" />
            <PropertyEpcData property={property} className="border-primary hidden md:flex xl:border-r-2" />
            <PropertyStatusData property={property} className="hidden xl:flex" />
          </div>
        </div>
        <div className="flex w-20 min-w-fit flex-row justify-center gap-4">
          <IconButton id={`${property.id}-delete`} style="delete" onClick={() => setShowDeleteModal(true)}>
            <MdDelete size={20} />
          </IconButton>
          <Link id={`${property.id}-view`} to={`/properties/${property.id}`}>
            <IconButton style="secondary">
              <BiShow size={20} />
            </IconButton>
          </Link>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          callback={handleDeleteProperty}
          onClose={() => setShowDeleteModal(false)}
          value={`${property.addressLine1}, ${property.addressLine2 ? `${property.addressLine2} ,` : ''} ${property.city}, ${property.postcode}`}
        />
      )}
    </>
  );
};

export default PropertyRow;

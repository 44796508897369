import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdFilterList } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { Accordion, Button, IconButton } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import BulkUploadModal from '@/components/bulk-upload';
import { CreateNewPropertyModal } from '@/components/create-property';
import useUserCompany from '@/hooks/company/useUserCompany';
import useProperties from '@/hooks/properties/useProperties';
import usePropertyCount from '@/hooks/properties/usePropertyCount';
import { Filters } from '@/models/properties';
import FiltersForm from './Filters';
import PropertiesTable from './PropertiesTable';
import PropertyHeader from './PropertyHeader';

const Properties = () => {
  const [filters, setFilters] = useState<boolean>(true);
  const [createPropertyModal, setCreatePropertyModal] = useState<boolean>(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState<boolean>(false);

  const { company } = useUserCompany();

  const defaultValues = {
    currentEpcFilters: [],
    potentialEpcFilters: [],
    jobStatusFilters: [],
    search: '',
  };

  const {
    register,
    control,
    watch,
    getValues,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<Filters>({ defaultValues });
  const values = watch(['currentEpcFilters', 'potentialEpcFilters', 'jobStatusFilters', 'search']);
  const { currentEpcFilters, potentialEpcFilters, jobStatusFilters, search } = getValues();
  const {
    properties,
    mutate: refetchProperties,
    isLoading,
  } = useProperties({
    currentEpcFilters,
    potentialEpcFilters,
    jobStatusFilters,
    search,
  });
  const { count, mutate: refetchPropertyCount } = usePropertyCount();
  const isMobile = useMediaQuery(1023);
  const refetch = useCallback(() => {
    refetchProperties();
    refetchPropertyCount();
  }, [refetchProperties, refetchPropertyCount]);

  useEffect(() => {
    handleSubmit(() => {
      refetch();
    });
  }, [values, handleSubmit, refetch]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const search = searchParams.get('search');
    if (!search) return;
    setValue('search', search);
  }, [searchParams, setValue]);

  return (
    <div>
      <Accordion
        accordionOpen={isMobile ? !filters : filters}
        accordionChildren={
          <FiltersForm control={control} register={register} errors={errors} reset={() => reset(defaultValues)} />
        }
      >
        <div className="flex w-full flex-row gap-4">
          <div className="m-auto flex flex-1 flex-row justify-between">
            <h1 className="text-xl">Properties</h1>
            <IconButton id="filters-button" style="secondary" onClick={() => setFilters(!filters)}>
              <MdFilterList size={20} />
            </IconButton>
          </div>
          <div className="flex flex-row justify-end gap-4">
            <div className="hidden w-fit md:block">
              <Button style="secondary" onClick={() => setShowBulkUploadModal(true)}>
                Bulk Upload
              </Button>
            </div>
            <div className="w-36">
              <Button id="add-new-button" onClick={() => setCreatePropertyModal(true)}>
                Add new
              </Button>
            </div>
          </div>
        </div>
      </Accordion>
      <PropertyHeader />
      <PropertiesTable
        company={company}
        count={count}
        isLoading={isLoading}
        properties={properties}
        refetch={refetch}
      />
      {createPropertyModal && (
        <CreateNewPropertyModal
          onClose={() => {
            refetch();
            setCreatePropertyModal(false);
          }}
        />
      )}
      {showBulkUploadModal && (
        <BulkUploadModal
          onClose={() => {
            refetch();
            setShowBulkUploadModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Properties;

import React from 'react';
import { Control, FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { FiltersContainer, Select, TextInput } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { jobStatusOptions } from '@epcbuilder/lib/models/jobs';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';
import { Filters } from '@/models/properties';

const FiltersForm = <T extends FieldValues>({
  control,
  register,
  errors,
  reset,
}: {
  control: Control<T>;
  register: UseFormRegister<Filters>;
  errors: FieldErrors<Filters>;
  reset: () => void;
}) => {
  const isMobile = useMediaQuery(1023);
  return (
    <FiltersContainer id="properties-filters" reset={reset}>
      <div className="w-42 md:w-52">
        <Select
          multiple={true}
          control={control}
          id="epcCurrent"
          name={'currentEpcFilters' as Path<T>}
          title="Filter by current EPC rating"
          label={isMobile ? '' : 'Current EPC'}
          placeholder="Filter Current EPC"
          options={epcRatingOptions}
        />
      </div>
      <div className="w-42 md:w-52">
        <Select
          multiple={true}
          control={control}
          id="jobStatus"
          name={'jobStatusFilters' as Path<T>}
          title="Filter by status"
          label={isMobile ? '' : 'Job Status'}
          placeholder="Filter Job Status"
          options={jobStatusOptions}
        />
      </div>
      <div className="w-42 md:w-52 md:self-end lg:w-96">
        <TextInput
          {...register('search')}
          id="search"
          name="search"
          title="your search query"
          placeholder="Search"
          error={errors.search?.message}
        />
      </div>
    </FiltersContainer>
  );
};

export default FiltersForm;

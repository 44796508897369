import React, { useCallback } from 'react';
import { BiBuilding, BiCheck, BiX } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { IconButton } from '@epcbuilder/lib/components';
import { CompanyJoinRequest } from '@epcbuilder/lib/models/companies';
import { acceptRequest, rejectRequest } from '@/network/companies';

const CompanyRequestRow = ({ request }: { request: CompanyJoinRequest; refetch: () => void }) => {
  function sleep(ms: number | 0) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleAccept = useCallback(async () => {
    try {
      await acceptRequest({ requestId: request.id });
      toast.success('Successfully accepted join request', { toastId: 'accept-join-success' });
      sleep(3000);
      window.location.reload();
    } catch (error: unknown) {
      toast.error('There was a problem accepting the join request. Please try again.');
    }
  }, [request]);

  const handleDecline = useCallback(async () => {
    try {
      await rejectRequest({ requestId: request.id });
      toast.success('Successfully rejected join request', { toastId: 'reject-join-success' });
      window.location.reload();
    } catch (error: unknown) {
      toast.error('There was a problem rejecting the join request. Please try again.');
    }
  }, [request]);

  return (
    <>
      <div
        id={request.id.toString()}
        className="border-neutral hover:bg-neutral-dark/10 flex min-h-20 w-full flex-row items-center justify-between border-b-2 px-4"
      >
        <div className="flex size-full min-h-12 flex-row gap-2">
          <div className="flex flex-1 flex-row gap-2 p-2 ">
            <div className={`border-primary mr-4 h-full min-w-fit flex-1 md:border-r-2`}>
              <p id="requested-company-name" className="flex h-full w-64 flex-row items-center gap-4 ">
                <BiBuilding size="20" />
                {request.company.companyName}
              </p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
              <p id="requested-by-name" className="flex h-full w-64 flex-row items-center gap-4">
                {request.requestedBy.firstName} {request.requestedBy.lastName} ({request.requestedBy.email})
              </p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
              <p className="flex h-full w-72 flex-row items-center gap-4"></p>
            </div>
          </div>
        </div>
        <div className="flex w-20 min-w-fit flex-row justify-center gap-4">
          <IconButton id="accept-request-button" style="secondary" onClick={handleAccept}>
            <BiCheck title="Accept request" size="25" />
          </IconButton>
          <IconButton id="decline-request-button" style="delete" onClick={handleDecline}>
            <BiX title="Decline request" size="25" />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default CompanyRequestRow;

import React from 'react';
import { Loading } from '@epcbuilder/lib/components';
import { Property } from '@epcbuilder/lib/models/properties';
import JobHistoryRow from './JobHistoryRow';

const JobHistoryTable = ({
  count,
  isLoading,
  properties,
  refetch,
}: {
  count: number | undefined;
  isLoading: boolean;
  properties: Property[] | undefined;
  refetch: () => void;
}) => {
  if (isLoading || !properties) {
    return <Loading id="properties-loading" />;
  }

  if (count === 0) {
    return (
      <p id="properties-count-empty" className="my-8 flex items-center justify-center">
        No properties have been created yet
      </p>
    );
  }

  if (properties.length === 0) {
    return (
      <p id="properties-filters-empty" className="my-8 flex items-center justify-center">
        There are no properties that match these filters
      </p>
    );
  }

  return (
    <div id="properties-table">
      {properties.map((property) => (
        <JobHistoryRow key={property.jobId} property={property} refetch={refetch} />
      ))}
    </div>
  );
};

export default JobHistoryTable;

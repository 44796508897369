import { CompanyPermissions } from '@epcbuilder/lib/models/companies';
import useSWR from 'swr';
import { getPermissions } from '@/network/companies';

const useCompanyPermissions = (companyId: number | undefined) => {
  const { data, error, mutate, isLoading } = useSWR<CompanyPermissions>(
    `get-permissions`,
    async () => await getPermissions({ companyId })
  );

  return {
    permissions: data,
    error,
    mutate,
    isLoading,
  };
};

export default useCompanyPermissions;

import React, { useCallback, useState } from 'react';
import { BiMailSend, BiUser } from 'react-icons/bi';
import { IoMdSettings } from 'react-icons/io';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { TiUserDeleteOutline } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, IconButton } from '@epcbuilder/lib/components';
import Modal, { RemoveUserFromCompanyModal } from '@epcbuilder/lib/components/Modal';
import { Company } from '@epcbuilder/lib/models/companies';
import { User } from '@epcbuilder/lib/models/user';
import { MuiColorInput } from 'mui-color-input';
import { removeUserFromCompany, updateCompanyColor } from '@/network/companies';

const CompanyUserRow = ({ user, company, refetch }: { user: User; company: Company; refetch: () => void }) => {
  const [showRemoveUserModal, setShowRemoveUserModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const [userIconColor, setUserIconColour] = React.useState(user.companyUserIconColour);
  const handleIconColourChange = (newValue: React.SetStateAction<string>) => {
    setUserIconColour(newValue);
  };

  const submitChanges = async () => {
    try {
      await updateCompanyColor({ companyId: company.id, userId: user.id, userIconColor: userIconColor });
      toast.success('Successfully updated user company settings', { toastId: 'update-success' });
      refetch();
    } catch (error: unknown) {
      toast.error('There was a problem updating user company settings. Please try again.');
    }
  };

  const handleRemoveUser = useCallback(async () => {
    try {
      await removeUserFromCompany({ userid: user.id, companyid: company.id });
      toast.success('Successfully removed', { toastId: 'remove-success' });
      refetch();
    } catch (error: unknown) {
      toast.error('There was a problem removing the user from the company. Please try again.');
    }
  }, [refetch, user, company.id]);

  return (
    <>
      <div
        id={'company-user-' + user.id}
        className="border-neutral hover:bg-neutral-dark/10 flex min-h-20 w-full flex-row items-center justify-between border-b-2 px-4"
      >
        <div className="flex size-full min-h-12 flex-row gap-2">
          <div className="flex flex-1 flex-row gap-2 p-2 ">
            <div className={`border-primary h-full min-w-fit flex-1 md:border-r-2`}>
              <p id="company-user-name" className="flex h-full w-[11rem] flex-row items-center gap-4 ">
                <BiUser size="20" />
                {user.firstName} {user.lastName}
              </p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
              <p className="flex h-full w-64 flex-row items-center gap-4">{user.email}</p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
              <p className="flex h-full w-72 flex-row items-center gap-4"></p>
            </div>
          </div>
        </div>
        <div>
          <div className="sm:hidden">
            <div className="flex w-20 min-w-fit flex-row justify-center gap-4">
              <Link to={`/properties?search=${`${user.firstName} ${user.lastName}`}`}>
                <IconButton style="secondary">
                  <PiHouseSimpleBold title="View jobs" size={15} />
                </IconButton>
              </Link>
              <IconButton style="secondary" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {dropdownOpen ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
              </IconButton>
            </div>
            {dropdownOpen && (
              <div className="bg-color border-neutral absolute right-4 mt-2 w-48 rounded-md border-2 shadow-lg">
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        setShowSettingsModal(true);
                        setDropdownOpen(false);
                      }}
                      className="flex w-full items-center gap-2 p-2 text-left hover:bg-gray-100"
                    >
                      <IconButton style="secondary">
                        <IoMdSettings title="Settings" size={20} />
                      </IconButton>
                      <p>User Settings</p>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setShowRemoveUserModal(true);
                        setDropdownOpen(false);
                      }}
                      className="flex w-full items-center gap-2 p-2 text-left hover:bg-gray-100"
                    >
                      <IconButton style="delete">
                        <TiUserDeleteOutline title="Remove user" size={20} />
                      </IconButton>
                      <p>Remove User</p>
                    </button>
                  </li>
                  <li>
                    <a
                      href={'mailto:' + user.email}
                      className="w-full"
                      onClick={() => {
                        setDropdownOpen(false);
                      }}
                    >
                      <div className="flex w-full items-center gap-2 p-2 text-left hover:bg-gray-100">
                        <IconButton style="secondary">
                          <BiMailSend title="Send email" size={20} />
                        </IconButton>
                        <p>Email</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="hidden flex-row gap-4 sm:flex">
            <IconButton style="secondary" onClick={() => setShowSettingsModal(true)}>
              <IoMdSettings title="Settings" size={20} />
            </IconButton>
            <IconButton style="delete" onClick={() => setShowRemoveUserModal(true)}>
              <TiUserDeleteOutline title="Remove user" size={20} />
            </IconButton>
            <IconButton style="secondary">
              <a href={'mailto:' + user.email}>
                <BiMailSend title="Send email" size={20} />
              </a>
            </IconButton>
            <Link to={`/properties?search=${`${user.firstName} ${user.lastName}`}`}>
              <IconButton style="secondary">
                <PiHouseSimpleBold title="View jobs" size={20} />
              </IconButton>
            </Link>
          </div>
        </div>
      </div>
      {showRemoveUserModal && (
        <RemoveUserFromCompanyModal
          callback={handleRemoveUser}
          onClose={() => setShowRemoveUserModal(false)}
          value={`${user.firstName} ${user.lastName}`}
        />
      )}
      {showSettingsModal && (
        <Modal id="remove-user-from-company-modal" onClose={() => setShowSettingsModal(false)}>
          <h1 className="font-header text-lg">
            {user.firstName} {user.lastName} Settings
          </h1>

          <div className="flex gap-4 pt-2">
            <p className="mt-4 align-top font-bold">Set Icon Colour</p>
            <MuiColorInput
              variant="outlined"
              size="small"
              format="hex"
              value={userIconColor}
              onChange={handleIconColourChange}
            />
          </div>

          <div className="mx-auto mt-4 w-fit">
            <Button onClick={submitChanges} style="primary">
              Save Changes
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CompanyUserRow;

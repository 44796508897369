import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AuthLayout } from '@epcbuilder/lib/components';
import { ResendVerificationForm } from '@/components/resend-verification';

const ResendVerificationPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Resend Verification Email">
        <p className="text-color-inverse mt-4">
          Your registration has not yet been confirmed, either click the link in the e-mail which was sent to you or
          click here to resend the e-mail.
        </p>
        <div className="mt-8 flex flex-row items-center">
          <p className="text-color-inverse">
            Go back?{' '}
            <Link id="login-link" className="text-link" to="/">
              Login now
            </Link>
          </p>
        </div>
        <ResendVerificationForm />
      </AuthLayout>
    </>
  );
};

export default ResendVerificationPage;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card } from '@epcbuilder/lib/components';
import { BookingSlotTimeframe, JobSurvey, SurveyStatus, SurveyStatusStrings } from '@epcbuilder/lib/models/jobs';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { format } from 'date-fns';
import useSurveyBooking from '@/hooks/surveys/useSurveyBooking';
import { BookingSlot } from '@/models/job';
import { postSurveyBookingNotes } from '@/network/surveys';
import BookingNotesModal from './modals/BookingNotesModal';

const SurveyDetailsCard = ({ jobSurvey }: { jobSurvey: JobSurvey }) => {
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [initialNotes, setInitialNotes] = useState<string>('');
  const { surveyBooking, mutate: refetchSurveyBooking } = useSurveyBooking({ id: jobSurvey?.id });

  const confirmedBooking = [
    SurveyStatus.SURVEY_BOOKED,
    SurveyStatus.SURVEY_COMPLETED,
    SurveyStatus.REPORT_IN_PROGRESS,
    SurveyStatus.REPORT_COMPLETED,
  ].includes(jobSurvey.surveyStatusID);

  useEffect(() => {
    if (jobSurvey?.id) {
      refetchSurveyBooking();
    }
  }, [jobSurvey, refetchSurveyBooking]);

  useEffect(() => {
    if (surveyBooking?.notes) {
      setInitialNotes(surveyBooking.notes);
    }
  }, [surveyBooking]);

  const getBookingSlotString = (bookingSlot?: BookingSlot): string => {
    if (bookingSlot === BookingSlot.Am) {
      return `${BookingSlotTimeframe.AmSlotStartHour} AM - ${BookingSlotTimeframe.AmSlotEndHour} PM`;
    } else if (bookingSlot === BookingSlot.Pm) {
      return `${BookingSlotTimeframe.PmSlotStartHour} PM - ${BookingSlotTimeframe.PmSlotEndHour} PM`;
    } else {
      return '-';
    }
  };

  const handleSaveNotes = async (notes: string) => {
    try {
      if (surveyBooking) {
        await postSurveyBookingNotes(surveyBooking?.id, { notes });
        await refetchSurveyBooking();
        toast.success('Booking notes updated.', { toastId: 'booking-notes-success' });
        setShowNotesModal(false);
      }
    } catch (error) {
      handleUnknownDetail(error);
    }
  };

  const handleOpenModal = () => {
    setShowNotesModal(true);
    if (surveyBooking?.notes) {
      setInitialNotes(surveyBooking.notes);
    }
  };

  return (
    <>
      <Card>
        <div className="flex flex-col gap-2">
          <p className="pl-4">Survey status:</p>
          <div className="bg-blue dark:bg-primary-dark flex items-center justify-center rounded-[16px] p-4">
            <p id="survey-status" className="text-center font-bold">
              {SurveyStatusStrings[jobSurvey.surveyStatusID]}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 pl-4">
          <div className="flex flex-row xl:flex-col">
            <p className="flex-1">Surveyor:</p>
            <p id="surveyor-name" className="flex-1 truncate font-bold">
              {confirmedBooking ? surveyBooking?.assessorName : '-'}
            </p>
          </div>
          <div className="flex flex-row xl:flex-col">
            <p className="flex-1">Date:</p>
            <p id="survey-date" className="flex-1 font-bold">
              {confirmedBooking && surveyBooking?.surveyDate
                ? format(new Date(surveyBooking.surveyDate), 'dd/MM/yyyy')
                : '-'}
            </p>
          </div>
          <div className="flex flex-row xl:flex-col">
            <p className="flex-1">Time:</p>
            <p id="survey-time" className="flex-1 font-bold">
              {confirmedBooking ? getBookingSlotString(surveyBooking?.bookingSlot) : '-'}
            </p>
          </div>
        </div>
        <Button id="notes-button" style="secondary" disabled={!confirmedBooking} onClick={handleOpenModal}>
          Notes
        </Button>
      </Card>
      {showNotesModal && (
        <BookingNotesModal
          onClose={() => setShowNotesModal(false)}
          initialNotes={initialNotes}
          onSave={handleSaveNotes}
        />
      )}
    </>
  );
};

export default SurveyDetailsCard;

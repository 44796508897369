import React, { useEffect } from 'react';
import { Modal } from '@epcbuilder/lib/components';

const EligibleSuccessModal = ({
  onClose,
  refetchJobEligibilityStatus,
}: {
  onClose: () => void;
  refetchJobEligibilityStatus: () => void;
}) => {
  useEffect(() => {
    refetchJobEligibilityStatus();
  }, [refetchJobEligibilityStatus]);

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col items-center gap-4">
        <img src="/images/eligible-success.png" alt="eligibility-success" height={170} width={170} />
        <p className="text-lg font-bold uppercase">Thank you, we'll be in touch shortly</p>
        <p>
          Keep your phone close! A member of the ECO funding team will call you from an 0161 number soon to continue
          your journey.
        </p>
      </div>
    </Modal>
  );
};

export default EligibleSuccessModal;

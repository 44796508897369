import React from 'react';
import { MdOutlineEnergySavingsLeaf, MdOutlineLocationOn, MdOutlineMonitorHeart } from 'react-icons/md';
import { JobStatusStrings } from '@epcbuilder/lib/models/jobs';
import { Property } from '@epcbuilder/lib/models/properties';
import { capitalize } from '@epcbuilder/lib/utils/generic';
import { format } from 'date-fns';

export const PropertyAddressData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`mr-4 min-w-fit flex-1 ${className}`}>
      <div className="flex h-full flex-row items-center gap-4 sm:w-64">
        <MdOutlineLocationOn size={24} />
        <div className="flex flex-col">
          <p id={`${property.id}-line1-line2`}>{`${property.addressLine1} ${property.addressLine2}`}</p>
          <p id={`${property.id}-city-postcode`}>{`${property.city} ${property.postcode}`}</p>
        </div>
      </div>
    </div>
  );
};

export const PropertyEpcData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`mr-4 min-w-fit flex-1 ${className}`}>
      <div className="flex h-full w-64 flex-row items-center gap-4">
        <MdOutlineEnergySavingsLeaf size={24} />
        <div className="flex flex-row items-center gap-2">
          <p>Current EPC Rating:</p>
          <p id={`${property.id}-currentEpc`} className="font-bold">
            {property.currentEPCRating}
          </p>
        </div>
      </div>
    </div>
  );
};

export const PropertyStatusData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`mr-4 min-w-fit flex-1 ${className}`}>
      <div className="flex h-full w-72 flex-row items-center gap-4">
        <MdOutlineMonitorHeart size={24} />
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            <p>Status:</p>
            <p id={`${property.id}-status`} className="font-bold">
              {property.jobStatus ? capitalize(JobStatusStrings[property.jobStatus]) : 'Unknown'}
            </p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <p>Last Updated:</p>
            <p id={`${property.id}-statusUpdatedOn`} className="font-bold">
              {property.statusUpdatedOn && format(new Date(property.statusUpdatedOn), 'dd/MM/yyyy')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

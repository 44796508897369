import React from 'react';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';
import { Modal } from '@epcbuilder/lib/components';

export const ContactModal = ({ setShowContactModal }: { setShowContactModal: (state: boolean) => void }) => {
  return (
    <Modal onClose={() => setShowContactModal(false)}>
      <div className="flex flex-col gap-4">
        <h1 className="text-xl">Contact Us</h1>
        <div className="flex flex-col">
          <button
            id="contact-us-phone-button"
            className="bg-blue dark:bg-primary font-header flex h-12 min-w-fit items-center justify-center rounded-xl px-4 text-base drop-shadow-[0px_4px_0px_#9CCEC8] dark:drop-shadow-[0px_4px_0px_#0D695C]"
          >
            <a href="tel:08000584140">
              <p className="text-dark dark:text-light flex flex-row gap-2">
                <MdOutlinePhone size={20} />
                Call: 0800 0584140
              </p>
            </a>
          </button>
        </div>
        <div className="flex flex-col">
          <button
            id="contact-us-email-button"
            className="bg-blue dark:bg-primary font-header flex h-12 min-w-fit items-center justify-center rounded-xl px-4 text-base drop-shadow-[0px_4px_0px_#9CCEC8] dark:drop-shadow-[0px_4px_0px_#0D695C]"
          >
            <a href="mailto:info@epcbuilder.com">
              <p className="text-dark dark:text-light flex flex-row gap-2">
                <MdOutlineEmail size={20} />
                Email: info@epcbuilder.com
              </p>
            </a>
          </button>
        </div>
      </div>
    </Modal>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { Button } from '@epcbuilder/lib/components';

const BookingNotesModal = ({
  onClose,
  initialNotes,
  onSave,
}: {
  onClose: () => void;
  initialNotes: string;
  onSave: (notes: string) => void;
}) => {
  const { register, handleSubmit } = useForm<{ notes: string }>({
    defaultValues: { notes: initialNotes },
  });

  const handleSave = (data: { notes: string }) => {
    onSave(data.notes);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <button type="button" className="bg-modal-blur fixed inset-0 opacity-20" onClick={onClose} />
      <div className="bg-modal border-primary sm:man-w-[450px] relative flex flex-col overflow-auto rounded-xl p-8 shadow  sm:max-h-[600px] sm:min-w-[400px]">
        <button type="button" className="text-primary-lighter absolute right-0 top-0 p-4" onClick={onClose}>
          <MdClose size={24} />
        </button>
        <p id="heading" className="text-center font-bold uppercase">
          Booking Notes
        </p>
        <form className="mt-4 flex flex-col" onSubmit={handleSubmit(handleSave)}>
          <div className="flex flex-col">
            <div className="mb-4">
              <p className="mb-2 ml-2 mt-4 font-bold">Notes</p>
              <textarea
                placeholder="Is there anything else you'd like us to know?"
                {...register('notes')}
                id="notes"
                className="text-dark border-blue focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 text-base outline-none"
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button style="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" style="custom">
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingNotesModal;

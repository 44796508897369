import React from 'react';
import { BookingSlotTimeframe } from '@epcbuilder/lib/models/jobs';
import { isSameDay } from 'date-fns';
import { BookingAvailabilitySlot, BookingSlot } from '@/models/job';
const BookingSlotCalendar = ({
  date,
  availableSlots,
  onSlotSelect,
}: {
  date: string;
  availableSlots: BookingAvailabilitySlot[] | undefined;
  onSlotSelect: (date: Date, slot: BookingSlot) => void;
}) => {
  const findAvailableSlot = () => {
    const amSlot = availableSlots?.find((slot) => isSameDay(new Date(slot.date), new Date(date)) && slot.slot === 1);
    const pmSlot = availableSlots?.find((slot) => isSameDay(new Date(slot.date), new Date(date)) && slot.slot === 2);

    return {
      date: date,
      am: amSlot ? amSlot.available : false,
      pm: pmSlot ? pmSlot.available : false,
    };
  };

  const slot = findAvailableSlot();

  const handleSlotClick = (selectedSlot: BookingSlot) => {
    const slotDate = new Date(date);
    onSlotSelect(slotDate, selectedSlot);
  };

  const renderSlots = (daySchedule: { date: string; am: boolean; pm: boolean }) => {
    return (
      <>
        {daySchedule.am ? (
          <button
            type="button"
            onClick={() => handleSlotClick(1)}
            className="bg-blue dark:bg-primary mt-2 flex h-[100px] w-[70px] cursor-default flex-col items-center justify-center rounded-[12px] lg:h-[195px]"
          >
            <p className="font-bold leading-tight">AM</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.AmSlotStartHour}</p>
            <p className="font-bold leading-tight">-</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.AmSlotEndHour}</p>
          </button>
        ) : (
          <div className="bg-neutral dark:bg-dark-lightest mt-2 flex h-[100px] w-[70px] flex-col items-center justify-center rounded-[12px] lg:h-[195px]">
            <p className="font-bold leading-tight">not</p>
            <p className="font-bold leading-tight">avail.</p>
          </div>
        )}
        {daySchedule.pm ? (
          <button
            type="button"
            onClick={() => handleSlotClick(2)}
            className="bg-blue dark:bg-primary mt-2 flex h-[100px] w-[70px] cursor-default flex-col items-center justify-center rounded-[12px] lg:h-[195px]"
          >
            <p className="font-bold leading-tight">PM</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.PmSlotStartHour}</p>
            <p className="font-bold leading-tight">-</p>
            <p className="font-bold leading-tight">{BookingSlotTimeframe.PmSlotEndHour}</p>
          </button>
        ) : (
          <div className="bg-neutral dark:bg-dark-lightest mt-2 flex h-[100px] w-[70px] flex-col items-center justify-center rounded-[12px] lg:h-[195px]">
            <p className="font-bold leading-tight">not</p>
            <p className="font-bold leading-tight">avail.</p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">{slot && renderSlots(slot)}</div>
      <div className="border-blue dark:border-primary-dark h-full border-r-[2px]" />
    </>
  );
};

export default BookingSlotCalendar;

import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthLayout } from '@epcbuilder/lib/components';
import { ConfirmEmailForm } from '@/components/confirm-email';

const ConfirmEmailPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - Confirm Email</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Confirming Email">
        <ConfirmEmailForm />
      </AuthLayout>
    </>
  );
};

export default ConfirmEmailPage;

import useSWR from 'swr';
import { EligibilityStatus } from '@/models/job';
import { getJobEligibilityStatus } from '@/network/jobs';

const useJobEligibilityStatus = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<EligibilityStatus>(
    id ? `job-eligibility-status-${id}` : null,
    async () => (id ? await getJobEligibilityStatus({ id }) : null)
  );

  return {
    jobEligibilityStatus: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobEligibilityStatus;

import useSWR from 'swr';
import { getPropertyCount } from '@/network/properties';

type PropertyCount = {
  propertyCount: number;
};

const usePropertyCount = () => {
  const { data, error, mutate, isLoading } = useSWR<PropertyCount>(
    'properties-count-data',
    async () => await getPropertyCount()
  );

  return {
    count: data && data.propertyCount,
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyCount;

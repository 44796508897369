import { JobInstallation } from '@epcbuilder/lib/models/jobs';
import useSWR from 'swr';
import { getJobInstallation } from '@/network/jobs';

const useJobInstallation = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<JobInstallation>(id ? `job-installation-${id}` : null, async () =>
    id ? await getJobInstallation({ id }) : null
  );

  return {
    jobInstallation: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobInstallation;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { Card, IconButton } from '@epcbuilder/lib/components';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';

const TenantDetailsCard = ({
  setShowUpdateAccessDetailsModal,
  setProceedToEmailTenantModal,
  landlordManaged,
}: {
  setShowUpdateAccessDetailsModal: Dispatch<SetStateAction<boolean>>;
  setProceedToEmailTenantModal: Dispatch<SetStateAction<boolean>>;
  landlordManaged: boolean;
}) => {
  const { property } = usePropertyContext();
  const { job } = useJobContext();

  const [showTenantDetails, setShowTenantDetails] = useState<boolean>(false);

  return (
    <Card
      tooltipId="tooltip-access-details"
      tooltipText="If someone else will be giving access to the Assessor please enter their details here"
    >
      <div className="relative flex flex-col gap-4">
        <button
          type="button"
          onClick={() => setShowTenantDetails(!showTenantDetails)}
          className="flex flex-row items-start justify-between"
        >
          <p className="font-bold lg:w-full lg:p-0 lg:text-center">
            {landlordManaged ? 'Tenant details' : 'Access details'}
          </p>
        </button>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 lg:flex-row xl:flex-col">
            <div className="flex flex-1 flex-row lg:flex-col">
              <p className="flex-1">Name:</p>
              <p className="flex-[2] truncate">
                {property?.accessDetails.firstName !== null && property?.accessDetails.lastName !== null
                  ? `${property?.accessDetails.firstName} ${property?.accessDetails.lastName}`
                  : `Not provided`}
              </p>
            </div>
            <div className="flex flex-1 flex-row lg:flex-col">
              <p className="flex-1">Phone:</p>
              <p className="flex-[2]">
                {property?.accessDetails.phone !== null ? property?.accessDetails.phone : 'Not provided'}
              </p>
            </div>
          </div>
          <div className="flex flex-row lg:flex-col">
            <p className="flex-1">Email:</p>
            <div className="flex-[2] truncate">
              <p className="max-w-[80%] truncate">
                {property?.accessDetails.email !== null ? property?.accessDetails.email : 'Not provided'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-2 right-2">
        <IconButton
          disabled={!job?.active}
          id="edit-tenant-details"
          onClick={() => {
            setProceedToEmailTenantModal(false);
            setShowUpdateAccessDetailsModal(true);
          }}
          style="secondary"
        >
          <MdEdit size={20} title="test" />
        </IconButton>
      </div>
    </Card>
  );
};

export default TenantDetailsCard;

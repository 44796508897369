import { ForgotPassword, Login, Register, ResetPassword } from '@epcbuilder/lib/models/auth';
import { api } from '@epcbuilder/lib/utils';

export const postLogin = async (data: Login) => {
  return await api.post('/auth/login', data);
};

export const postRefreshToken = async (data: { refreshToken: string }) => {
  return await api.post('/auth/refresh-token', data);
};

export const postRegister = async (data: Register) => {
  return await api.post('/auth/register', data);
};

export const postForgotPassword = async (data: ForgotPassword) => {
  return await api.post('/auth/forgot-password', data);
};

export const postResetPassword = async (data: ResetPassword) => {
  return await api.post('/auth/forgot-password/reset', data);
};

export const postConfirmEmail = async (data: { token: string }) => {
  return await api.post('/auth/confirm-email', data);
};

export const postResendVerificationToken = async (data: { email: string }) => {
  return await api.post('/auth/resend-verification-token', data);
};

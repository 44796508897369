import { Property } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getPropertyJobHistory } from '@/network/properties';

const usePropertyJobHistory = () => {
  const { data, error, mutate, isLoading } = useSWR<Property[]>(
    `properties-job-history`,
    async () => await getPropertyJobHistory()
  );

  return {
    properties: data,
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyJobHistory;

import React, { useState } from 'react';
import { MdOutlineInfo } from 'react-icons/md';
import { Card, EpcGraph } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { PropertyRating } from '@epcbuilder/lib/models/properties';

const EpcGraphCard = ({
  propertyRatings,
  propertyRatingError,
}: {
  propertyRatings: PropertyRating | undefined;
  propertyRatingError: boolean;
}) => {
  const isMobile = useMediaQuery(1023);
  const [showEpcTooltip, setShowEpcTooltip] = useState<boolean>(false);

  return (
    <Card>
      <div className="relative w-full">
        <EpcGraph propertyRating={propertyRatings?.currentRating} />
        {propertyRatingError && (
          <div
            id="property-no-rating"
            className="absolute left-0 top-0 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]"
          >
            <div className="bg-blue/90 dark:bg-primary-dark/90 max-w-[220px] rounded-[22px] p-4 shadow-lg">
              <p className="font-bold">Your property does not have an EPC rating. See next steps.</p>
            </div>
          </div>
        )}
      </div>
      <button
        type="button"
        onClick={() => isMobile && setShowEpcTooltip(!showEpcTooltip)}
        className={`${showEpcTooltip ? 'rounded-[16px]' : 'rounded-[22px]'} ${
          isMobile ? 'cursor-pointer' : 'cursor-default'
        } bg-blue dark:bg-primary-dark flex w-full flex-col gap-2`}
      >
        <div className="flex w-full flex-row items-center justify-between gap-1 px-4 py-2 lg:hidden">
          <p className="font-bold">Energy Performance Certificate</p>
          <MdOutlineInfo size={20} />
        </div>
        {(showEpcTooltip || !isMobile) && (
          <p className="px-4 pb-4 text-left text-sm lg:pt-4">
            An Energy Performance Certificate (EPC) shows how energy efficient your property is. Properties are given a
            rating from A (most efficient) to G (least efficient). The graph shows this property’s current energy
            efficiency.
          </p>
        )}
      </button>
    </Card>
  );
};

export default EpcGraphCard;

import { useEffect, useState } from 'react';

const useMediaQuery = (maxWidth: number) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleMediaChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches);
    };

    const mediaQueryList = window.matchMedia(`(max-width: ${maxWidth}px)`);
    mediaQueryList.addEventListener('change', handleMediaChange);

    setIsMobile(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaChange);
    };
  }, [maxWidth]);

  return isMobile;
};

export default useMediaQuery;

import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdClose, MdOutlineCalendarMonth } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, TextInput } from '@epcbuilder/lib/components';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { BookingSlot, SurveyBooking } from '@/models/job';
import { deleteSurveyBooking, patchSurveyBooking } from '@/network/surveys';

const BookingFormModal = ({
  date,
  slot,
  surveyId,
  onClose,
  surveyBookingId,
  refetchJobSurvey,
}: {
  date: string;
  slot: BookingSlot;
  surveyId: string;
  onClose: () => void;
  surveyBookingId: string;
  refetchJobSurvey: () => void;
}) => {
  const { register, handleSubmit, setError } = useForm<SurveyBooking>();
  const [loading, setLoading] = useState(false);

  const formatSurveyData = (data: SurveyBooking) => {
    const [date] = data.surveyDate.toString().split(' - ');
    const [day, month, year] = date.split('/');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const bookingSlot = slot;

    return {
      ...data,
      surveyDate: formattedDate,
      surveyId,
      bookingSlot,
      active: true,
    };
  };

  const handleFormSubmit: SubmitHandler<SurveyBooking> = async (data) => {
    setLoading(true);
    try {
      const formattedDate = formatSurveyData(data);
      const updatedFormData = { ...formattedDate, state: 2 };
      await patchSurveyBooking(surveyBookingId, updatedFormData);
      refetchJobSurvey();
      toast.success('Survey successfully booked.', { toastId: 'survey-booked' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors(setError, errors);
      handleUnknownDetail(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    if (surveyBookingId) {
      await deleteSurveyBooking({ id: surveyBookingId });
      refetchJobSurvey();
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <button type="button" className="bg-modal-blur fixed inset-0 opacity-20" onClick={handleCancel} />
      <div className="bg-modal border-primary relative flex h-screen w-screen flex-col overflow-auto p-8 shadow sm:h-auto sm:max-h-[600px] sm:w-full sm:max-w-lg sm:rounded-xl">
        <button type="button" className="text-primary-lighter absolute right-0 top-0 p-4" onClick={handleCancel}>
          <MdClose size={24} />
        </button>
        <p id="heading" className="text-center font-bold uppercase">
          Book Survey
        </p>
        <form className="mt-4 flex flex-col" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="relative mb-4">
            <p className="mb-2 ml-2 mt-4 font-bold">Selected Date</p>
            <TextInput
              {...register('surveyDate')}
              id="surveyDate"
              value={date}
              readOnly
              overrideBaseClassnames
              className="text-dark border-blue focus:border-primary-lighter h-10 w-full cursor-default rounded-xl border-2 px-4 text-base outline-none"
            />
            <MdOutlineCalendarMonth size={20} className="text-color absolute right-4 top-[67px] -translate-y-1/2" />
          </div>
          <div className="mb-4">
            <p className="mb-2 ml-2 mt-4 font-bold">Notes</p>
            <textarea
              placeholder="Is there anything else you'd like us to know?"
              {...register('notes')}
              id="notes"
              className="text-dark border-blue focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 text-base outline-none"
            />
          </div>
          <p className="ml-2 mt-4 font-bold">
            Your appointment is currently being held for you. <br />
            We’ll contact you soon to finalize the payment and secure your appointment.
          </p>
          <div className="mt-8 flex justify-between gap-4">
            <Button style="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button style="custom" type="submit" loading={loading}>
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingFormModal;

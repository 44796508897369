import { api } from '@epcbuilder/lib/utils';
import { EligiblityFormData, JobContact } from '@/models/job';

export const getJobCurrentStage = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/current-stage`);
};

export const getJobReport = async ({ id }: { id?: string }) => {
  return await api.getFile(`/job/${id}/report`, 'EPC Builder Report.pdf');
};

export const postJobSurvey = async ({ id }: { id: string }) => {
  return await api.post(`/job/${id}/survey`);
};

export const getJobSurvey = async ({ id }: { id: string }) => {
  return await api.get(`/job/${id}/survey`);
};

export const postJobEligibility = async ({ id, data }: { id?: string; data: EligiblityFormData }) => {
  return await api.post(`/job/${id}/eligibility`, data);
};

export const getJobEligibilityStatus = async ({ id }: { id: string }) => {
  return await api.get(`/job/${id}/eligibilitystatus`);
};

export const patchRequestCallback = async ({ id, type }: { id?: string; type: JobContact }) => {
  return await api.patch(`/job/${id}/contact/${type}`);
};

export const postJobInstallation = async ({ id }: { id: string }) => {
  return await api.post(`/job/${id}/installation`);
};

export const getJobInstallation = async ({ id }: { id: string }) => {
  return await api.get(`/job/${id}/installation`);
};

export const getJob = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}`);
};

export const postSendTenantEligibility = async ({ id, message }: { id?: string; message?: string }) => {
  return await api.post(`/job/${id}/send-tenant-eligibility`, { message });
};

export const getJobTenantEmailStatuses = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/tenant-email-statuses`);
};

import { Job } from '@epcbuilder/lib/models/jobs';
import useSWR from 'swr';
import { getJob } from '@/network/jobs';

const useJob = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<Job>(
    id ? `property-specific-job-${id}` : null, // Use null if id is undefined
    async () => (id ? await getJob({ id }) : null)
  );

  return {
    job: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJob;

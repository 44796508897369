import React from 'react';
import { Loading } from '@epcbuilder/lib/components';
import { Company } from '@epcbuilder/lib/models/companies';

export const CompanyUserData = ({ company, loading }: { company: Company; loading: boolean }) => {
  return loading ? (
    <Loading />
  ) : (
    <div className="m-4">
      <div className="mb-9 flex w-full flex-row items-center justify-between">
        <h1 className="text-xl">{company.companyName}</h1>
      </div>
      <div className="flex w-full flex-row items-center justify-between">
        <p>
          You are a part of {company.companyName} company. You will have access to all jobs of other company members.
        </p>
      </div>
    </div>
  );
};

export default CompanyUserData;

import React from 'react';
import { MdOutlineEnergySavingsLeaf, MdOutlineLocationOn, MdOutlineMonitorHeart } from 'react-icons/md';
import { JobStatusStrings } from '@epcbuilder/lib/models/jobs';
import { Property } from '@epcbuilder/lib/models/properties';
import { capitalize } from '@epcbuilder/lib/utils/generic';
import { format } from 'date-fns';

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const getPropertyOwnerInitials = (userFullName: string | undefined) => {
  if (userFullName) {
    const nameArray = userFullName!.split(' ');
    const initials = nameArray.shift()!.charAt(0) + nameArray.pop()?.charAt(0);
    return initials.toUpperCase();
  }

  return 'NA';
};

const getFontColour = (backgroundColor: string) => {
  const background = backgroundColor;
  const rgb = hexToRgb(background);
  const luminance = (0.299 * rgb!.r + 0.587 * rgb!.g + 0.114 * rgb!.b) / 255;

  if (luminance.valueOf() >= 0.5) {
    return 'black'; // bright color - dark font
  } else {
    return 'white'; // dark color - light font
  }
};

export const PropertyOwnerInitialsData = ({ property, className }: { property: Property; className?: string }) => {
  const backgroundColor = property.userIconColourHex;
  const textColor = getFontColour(property.userIconColourHex);

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
      className={`${className} 
        sideways w-6 text-center font-bold drop-shadow 
      `}
    >
      {getPropertyOwnerInitials(property.userFullName)}
    </div>
  );
};

export const PropertyAddressData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`mr-4 min-w-fit flex-1 ${className}`}>
      <div className="flex h-full flex-row items-center gap-4 sm:w-64">
        <MdOutlineLocationOn size={24} />
        <div className="flex flex-col">
          <p
            id={`${property.id}-line1-line2`}
          >{`${property.addressLine1}${property.addressLine2 ? `, ${property.addressLine2}` : ''}`}</p>
          <p id={`${property.id}-city-postcode`}>{`${property.city} ${property.postcode}`}</p>
        </div>
      </div>
    </div>
  );
};

export const PropertyEpcData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`mr-4 min-w-fit flex-1 ${className}`}>
      <div className="flex h-full w-64 flex-row items-center gap-4">
        <MdOutlineEnergySavingsLeaf size={24} />
        <div className="flex flex-row items-center gap-2">
          <p>Current EPC Rating:</p>
          <p id={`${property.id}-currentEpc`} className="font-bold">
            {property.currentEPCRating}
          </p>
        </div>
      </div>
    </div>
  );
};

export const PropertyStatusData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`mr-4 min-w-fit flex-1 ${className}`}>
      <div className="flex h-full w-72 flex-row items-center gap-4">
        <MdOutlineMonitorHeart size={24} />
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            <p>Status:</p>
            <p id={`${property.id}-status`} className="font-bold">
              {property.jobStatus ? capitalize(JobStatusStrings[property.jobStatus]) : 'Unknown'}
            </p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <p>Last Updated:</p>
            <p id={`${property.id}-statusUpdatedOn`} className="font-bold">
              {format(new Date(property.statusUpdatedOn), 'dd/MM/yyyy')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

export type MenuItems = {
  id: string;
  text: string;
  icon?: JSX.Element;
  onClick?: () => void;
};

const Menu = ({ items, className }: { items: MenuItems[]; className?: string }) => {
  return (
    <div className={`bg-color flex flex-col gap-2 p-2 sm:shadow ${className}`}>
      {items.map((item) => (
        <button
          type="button"
          key={item.id}
          className="hover:bg-primary hover:text-color-inverse flex cursor-pointer flex-row items-center gap-4 rounded-xl p-4"
          onClick={item.onClick}
        >
          <div className="text-color w-4">{item.icon}</div>
          <p>{item.text}</p>
        </button>
      ))}
    </div>
  );
};

export default Menu;

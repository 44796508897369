import React, { useState } from 'react';
import { Modal } from '@epcbuilder/lib/components';
import { Address } from '@epcbuilder/lib/models/properties';
import AddressConfirmationForm from './AddressConfirmationForm';
import PostcodeSearchForm from './PostcodeSearchForm';

const CreateNewPropertyModal = ({ onClose }: { onClose: () => void }) => {
  const [address, setAddress] = useState<Address | undefined>(undefined);

  return (
    <Modal id="create-property-modal" onClose={onClose}>
      <h1>Create Property</h1>
      {address ? (
        <AddressConfirmationForm address={address} setAddress={setAddress} onClose={onClose} />
      ) : (
        <PostcodeSearchForm setAddress={setAddress} />
      )}
    </Modal>
  );
};

export default CreateNewPropertyModal;

type QualifyingCriteria = {
  flexCriteria: string[];
  benefits: string[];
  incomeLess31k: boolean;
  isFlexEligiblePostcode: boolean;
  isFlexLsoaPostcode: boolean;
  isFlexMstPostcode: boolean;
};

export const doesQualify = (qc: QualifyingCriteria): boolean => {
  return meetsEco4(qc) || meetsEco4Flex(qc);
};

const meetsEco4 = (qc: QualifyingCriteria): boolean => {
  if (qc.benefits.length >= 1 && !qc.benefits.includes('noneoftheabove')) {
    return true;
  }

  return false;
};

const meetsEco4Flex = (qc: QualifyingCriteria): boolean => {
  if (!qc.isFlexEligiblePostcode) {
    return false;
  }

  if (qc.incomeLess31k) {
    return true;
  }

  if (qc.flexCriteria.includes('CriticalIllness')) {
    return true;
  }

  if (qc.flexCriteria.includes('CouncilTaxRebate') && qc.flexCriteria.includes('FreeSchoolMeals')) {
    return true;
  }

  if (qc.flexCriteria.includes('CouncilTaxRebate') && qc.flexCriteria.includes('NICE')) {
    return true;
  }

  if (qc.flexCriteria.includes('FreeSchoolMeals') && qc.flexCriteria.includes('NICE')) {
    return true;
  }

  if (qc.isFlexMstPostcode) {
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('CouncilTaxRebate')) {
      return true;
    }
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('FreeSchoolMeals')) {
      return true;
    }
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('NICE')) {
      return true;
    }
  }

  if (qc.isFlexLsoaPostcode) {
    if (qc.flexCriteria.includes('CouncilTaxRebate')) {
      return true;
    }
    if (qc.flexCriteria.includes('FreeSchoolMeals')) {
      return true;
    }
  }

  return false;
};

import React, { createContext, useContext } from 'react';
import { Job } from '@epcbuilder/lib/models/jobs';
import { Children } from '@epcbuilder/lib/utils';
import { KeyedMutator } from 'swr';
import useJob from '@/hooks/jobs/useJob';

type JobContextProps = {
  job?: Job;
  error: unknown;
  mutate: KeyedMutator<Job>;
  isLoading: boolean;
};

const JobContext = createContext<JobContextProps | undefined>(undefined);

const JobProvider = ({ id, children }: { id?: string; children: Children }) => {
  const { job, error, mutate, isLoading } = useJob({ id });

  const contextValue: JobContextProps = {
    job,
    error,
    mutate,
    isLoading,
  };

  return <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>;
};

const useJobContext = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error('useJob must be used within a JobProvider');
  }
  return context;
};

export { JobProvider, useJobContext };

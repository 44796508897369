import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import useJobEligibilityStatus from '@/hooks/jobs/useJobEligibilitystatus';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import { JobContact } from '@/models/job';
import { patchRequestCallback } from '@/network/jobs';
import { CallUsCard, RequestCallbackCard } from '../ContactCards';
import BookingPickerCard from './BookingPickerCard';
import NextStepsCard from './NextStepsCard';
import SurveyDetailsCard from './SurveyDetailsCard';

const EnergyAssessmentStep = () => {
  const { job } = useJobContext();
  const { jobSurvey, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });
  const { jobEligibilityStatus } = useJobEligibilityStatus({ id: job?.id });

  const handleRequestCallback = useCallback(async () => {
    try {
      await patchRequestCallback({ id: job?.id, type: JobContact.SURVEY });
      toast.success(
        'Callback has been requested, someone from the admin team will be in contact to confirm your booking as soon as possible.'
      );
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job]);

  if (!jobSurvey) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <div className="grid justify-center gap-4 lg:max-w-[520px] lg:grid-cols-3 xl:max-w-[900px] xl:grid-cols-12">
        <div className="w-full lg:col-span-3 xl:col-span-7 xl:row-span-2">
          <BookingPickerCard
            jobSurvey={jobSurvey}
            jobEligibilityStatus={jobEligibilityStatus}
            refetchJobSurvey={refetchJobSurvey}
          />
        </div>
        <div className="flex flex-row gap-4 lg:order-2 lg:col-span-1 lg:flex-col xl:col-span-2">
          <a className="size-full" href="tel:08000584140">
            <CallUsCard />
          </a>
          <RequestCallbackCard onClick={() => handleRequestCallback()} />
        </div>
        <div className="w-full lg:order-1 lg:col-span-2 lg:row-span-2 xl:col-span-3 xl:row-span-1">
          <SurveyDetailsCard jobSurvey={jobSurvey} />
        </div>
        <div className="w-full lg:order-3 xl:col-span-5">
          <NextStepsCard jobSurvey={jobSurvey} />
        </div>
      </div>
    </div>
  );
};

export default EnergyAssessmentStep;

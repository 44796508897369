import useSWR from 'swr';
import { SurveyBooking } from '@/models/job';
import { getSurveyBooking } from '@/network/surveys';

const useSurveyBooking = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<SurveyBooking>(id ? `survey-booking-${id}` : null, async () =>
    id ? await getSurveyBooking({ id }) : null
  );

  return {
    surveyBooking: data,
    error,
    mutate,
    isLoading,
  };
};

export default useSurveyBooking;

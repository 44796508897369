import React, { useEffect } from 'react';
import { Modal } from '@epcbuilder/lib/components';

const EligibleErrorModal = ({
  onClose,
  refetchJobEligibilityStatus,
}: {
  onClose: () => void;
  refetchJobEligibilityStatus: () => void;
}) => {
  useEffect(() => {
    refetchJobEligibilityStatus();
  }, [refetchJobEligibilityStatus]);

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col items-center gap-4">
        <img src="/images/eligible-error.png" alt="eligibility-error" height={170} width={170} />
        <p className="text-lg font-bold uppercase">Unfortunately, you're not eligible</p>
        <p>Based on your answers it looks like you're not eligible for a grant.</p>
        <p>
          To continue the journey to make your property more energy efficient, please close this window and go to the
          next step to book an Energy Assessment.
        </p>
      </div>
    </Modal>
  );
};

export default EligibleErrorModal;

import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, TextInput } from '@epcbuilder/lib/components';
import { ForgotPassword } from '@epcbuilder/lib/models/auth';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { postForgotPassword } from '@/network/auth';

const defaultValues: ForgotPassword = {
  email: '',
};

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
});

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ForgotPassword>({
    defaultValues,
    resolver: yupResolver(forgotPasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ForgotPassword> = async (data) => {
    try {
      await postForgotPassword({ email: data.email.trim() });
      toast.success('Password reset email has been sent, please check your emails.', {
        toastId: 'forgot-password-success',
      });
      navigate('/');
    } catch (error) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ForgotPassword>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email')}
        id="email"
        name="email"
        title="Your email address"
        placeholder="Email"
        error={errors.email?.message}
      />
      <Button id="forgot-password-submit" loading={isSubmitting} type="submit">
        Submit
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Loading } from '@epcbuilder/lib/components';
import { CompanyScreen } from '@/components/company';
import Layout from '@/components/Layout';
import withAuth from '@/hoc/withAuth';
import useUserCompany from '@/hooks/company/useUserCompany';

const CompanyDetailsPage = () => {
  const { company, error } = useUserCompany();

  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Company Details">
        {!company ? <Loading /> : <CompanyScreen error={error} company={company!} />}
      </Layout>
    </>
  );
};

export default withAuth(CompanyDetailsPage);

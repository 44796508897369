import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from '@epcbuilder/lib/components';

const PreTenantDetailsModal = ({
  setShowUpdateAccessDetailsModal,
  setProceedToEmailTenantModal,
  onClose,
}: {
  setShowUpdateAccessDetailsModal: Dispatch<SetStateAction<boolean>>;
  setProceedToEmailTenantModal: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col gap-4">
        <p>Your property is currently occupied by a tenant.</p>
        <p>The property may qualify for free home upgrades if your tenant meets certain criteria.</p>
        <p>
          Enter your tenants' details and we will send them an email with a link to check if they qualify (it takes less
          than 1 minute).
        </p>
        <Button
          onClick={() => {
            setProceedToEmailTenantModal(true);
            setShowUpdateAccessDetailsModal(true);
            onClose();
          }}
          style="primary"
        >
          Enter tenant information
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          style="secondary"
        >
          Another time
        </Button>
      </div>
    </Modal>
  );
};

export default PreTenantDetailsModal;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, Select } from '@epcbuilder/lib/components';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import download from 'downloadjs';
import { postPropertiesBulkUploader } from '@/network/properties';

const BulkUploadModal = ({ onClose }: { onClose: () => void }) => {
  const { statusOptions, isLoading: statusOptionsLoading } = usePropertyOwnerStatuses();
  const [fileErrors, setFileErrors] = useState<{ row: number; column: string; error: string }[]>([]);

  const defaultValues = {
    file: undefined,
    ownerStatusId: 0,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
  } = useForm<{ file: File; ownerStatusId: number }>({
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<{ file: File; ownerStatusId: number }> = useCallback(
    async (data) => {
      try {
        if (data.ownerStatusId === 0) {
          setError('ownerStatusId', { message: 'Owner status must be selected' });
          return;
        }

        const response = await postPropertiesBulkUploader(data);

        if (!response?.data?.success) {
          setFileErrors(response?.data?.errors);
          setError('file', { message: 'Error in file, please check and try again' });
          return;
        } else {
          toast.success('Properties successfully created', { toastId: 'bulk-properties-success' });
          onClose();
        }
      } catch (error: unknown) {
        handleUnknownDetail(error);
      }
    },
    [onClose, setError]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        clearErrors();
        setFileErrors([]);
        setValue('file', acceptedFiles[0]);
        handleSubmit(onSubmit)();
      } else {
        setError('file', { message: 'File type must be .csv' });
      }
    },
    [clearErrors, handleSubmit, onSubmit, setError, setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    maxFiles: 1,
  });

  const handleDownloadTemplate = useCallback(async () => {
    const res = await fetch(`/csv/bulk-upload-template.csv`);
    const template = await res.text();
    download(template, 'bulk-upload-template', 'text/csv');
  }, []);

  if (statusOptionsLoading || !statusOptions) return <></>;

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col gap-4">
        <p className="text-xl font-bold">Bulk Uploader</p>
        <Select
          control={control}
          id="ownerStatusId"
          name="ownerStatusId"
          title="Select owner status"
          placeholder="Select owner status"
          error={errors.ownerStatusId?.message}
          options={statusOptions}
        />
        <div {...getRootProps()} className="border-neutral grid h-40 place-items-center rounded-[12px] border-2">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the spreadsheet here ...</p>
          ) : isSubmitting ? (
            <p>Submitting...</p>
          ) : (
            <p>Drag or click to upload your bulk upload spreadsheet...</p>
          )}
        </div>
        {errors?.file?.message && (
          <div className="ml-2 flex flex-col gap-2">
            <p className="text-dark text-xs">
              Please either review and amend the rows with errors or remove these rows, save the file and try again.
              Alternatively please contact us on 0800 058 4140 for assistance.
            </p>
            <p className="text-error text-xs">{errors?.file?.message}</p>
            {fileErrors.length > 0 &&
              fileErrors.map((error, index) => (
                <p
                  key={index}
                  id={`file-error-${index}`}
                  className="text-error text-xs"
                >{`Row: ${error.row}${error.column ? `, Column: ${error.column}` : ''}, Error: ${error.error}`}</p>
              ))}
          </div>
        )}
        <div className="flex w-full justify-end">
          <div className="w-fit">
            <Button onClick={handleDownloadTemplate} style="secondary">
              Download Template
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BulkUploadModal;

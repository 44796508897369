import { api } from '@epcbuilder/lib/utils';
import { ChangePassword, Profile } from '@/models/user';

export const getUser = async () => {
  return await api.get('/account');
};

export const putProfile = async (data: Profile) => {
  return await api.put('/account', data);
};

export const patchPassword = async (data: ChangePassword) => {
  return await api.patch('/account/password', data);
};

import React from 'react';
import { Container } from '@epcbuilder/lib/components';
import { JobProvider } from '@/context/useJobContext';
import { PropertyProvider } from '@/context/usePropertyContext';
import EpcWizard from '../epc-wizard';

const Property = ({ jobId, propertyId }: { jobId: string; propertyId: string }) => {
  return (
    <PropertyProvider id={propertyId}>
      <JobProvider id={jobId}>
        <Container>
          <EpcWizard />
        </Container>
      </JobProvider>
    </PropertyProvider>
  );
};

export default Property;

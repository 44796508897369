import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AuthLayout } from '@epcbuilder/lib/components';
import { RegisterForm } from '@/components/register';

const RegisterPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Registration">
        <p className="text-color-inverse mt-4">
          Registering will enable us to send you relevant updates about your property(s). We will never share your
          details with third parties but may from time to time send you information we think may be of interest to you.
        </p>
        <div className="mt-8 flex flex-row items-center">
          <p className="text-color-inverse">
            Already have an account?{' '}
            <Link id="login-link" className="text-link" to="/">
              Sign in
            </Link>
          </p>
        </div>
        <RegisterForm />
      </AuthLayout>
    </>
  );
};

export default RegisterPage;

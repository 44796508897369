import React from 'react';
import { AppLayout, CookieModal, Main } from '@epcbuilder/lib/components';
import { Children } from '@epcbuilder/lib/utils';
import MobileBar from '@/components/navigation/MobileBar';
import Sidebar from '@/components/navigation/SideBar';
import TopBar from '@/components/navigation/TopBar';

const Layout = ({ title, children }: { title: string; children: Children }) => {
  return (
    <Main title={title}>
      <CookieModal marketingUrl={import.meta.env.VITE_MARKETING_URL} />
      <TopBar />
      <Sidebar />
      <MobileBar />
      <AppLayout>{children}</AppLayout>
    </Main>
  );
};

export default Layout;

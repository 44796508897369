import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationHandler } from '@epcbuilder/lib/components/Layout';
import { ReferrerCookieHandler } from '@epcbuilder/lib/components/ReferrerCookieHandler';
import ConfirmEmailPage from '@/app/confirm-email';
import ForgotPasswordPage from '@/app/forgot-password';
import LoginPage from '@/app/login';
import ProfilePage from '@/app/profile';
import PropertiesPage from '@/app/properties';
import PropertyPage from '@/app/property';
import RegisterPage from '@/app/register';
import ResendVerificationPage from '@/app/resend-verification';
import ResetPasswordPage from '@/app/reset-password';
import NotFoundPage from './app/404';
import CompanyDetailsPage from './app/company';
import JobPage from './app/job';
import JobHistoryPage from './app/job-history';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ReferrerCookieHandler>
        <NavigationHandler>
          <Routes>
            <Route path="/">
              <Route index element={<LoginPage />} />
              <Route path="register" element={<RegisterPage />} />
              <Route path="confirm-email/:token" element={<ConfirmEmailPage />} />
              <Route path="resend-verification" element={<ResendVerificationPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="reset-password/:token" element={<ResetPasswordPage />} />
              <Route path="properties/">
                <Route index element={<PropertiesPage />} />
                <Route path=":id" element={<PropertyPage />} />
              </Route>
              <Route path="job-history/">
                <Route index element={<JobHistoryPage />} />
                <Route path=":id" element={<JobPage />} />
              </Route>
              <Route path="profile" element={<ProfilePage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="company" element={<CompanyDetailsPage />} />
            </Route>
          </Routes>
        </NavigationHandler>
      </ReferrerCookieHandler>
    </BrowserRouter>
  );
};

export default AppRoutes;

import { api } from '@epcbuilder/lib/utils';
import { formatUtcDatetime } from '@epcbuilder/lib/utils/datetime';
import { SurveyBooking, SurveyBookingForm } from '@/models/job';

export const getSurveyBookingAvailableSlots = async ({ start, end }: { start: Date; end: Date }) => {
  return await api.get(
    `/survey/booking/available-slots?start=${formatUtcDatetime(start)}&end=${formatUtcDatetime(end)}`
  );
};

export const getSurveyBookingAvailability = async ({ start, end }: { start: Date; end: Date }) => {
  return await api.get(`/surveybooking/availability?start=${formatUtcDatetime(start)}&end=${formatUtcDatetime(end)}`);
};

export const getSurveyRequestedBooking = async ({ id }: { id?: string }) => {
  return await api.get(`/survey/${id}/requested-booking`);
};

export const postSurveyBooking = async ({
  id,
  selectedDate,
  slot,
}: {
  id: string;
  selectedDate: Date;
  slot: 1 | 2;
}) => {
  return await api.post(`/survey/${id}/booking`, { selectedDate: formatUtcDatetime(selectedDate), slot });
};

export const putSurveyBooking = async (data: SurveyBookingForm) => {
  const response = await api.put(`/surveybooking`, data);
  return response;
};

export const patchSurveyBooking = async (id: string, data: SurveyBooking) => {
  return await api.patch(`/surveybooking/${id}`, data);
};

export const deleteSurveyBooking = async ({ id }: { id: string }) => {
  return await api.delete(`/surveybooking/${id}`);
};

export const getSurveyBooking = async ({ id }: { id?: string }) => {
  return await api.get(`/survey/${id}/booking`);
};

export const postSurveyBookingNotes = async (id: string, data: { notes: string }) => {
  return await api.post(`/surveybooking/${id}/notes`, data);
};

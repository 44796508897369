import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Button } from '@epcbuilder/lib/components';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import useJobInstallation from '@/hooks/jobs/useJobInstallation';
import { EpcWizardSteps } from '@/models/job';
import { postJobInstallation } from '@/network/jobs';
import GenericNextStepsCard from '../GenericNextStepsCard';

const NextStepsCard = ({ setActive }: { setActive: Dispatch<SetStateAction<EpcWizardSteps>> }) => {
  const { job, mutate: refetchJob } = useJobContext();
  const { error: installationError, mutate: refetchJobInstallation } = useJobInstallation({ id: job?.id });

  const handleCreateInstallation = useCallback(async () => {
    try {
      if (!job?.id) throw Error;
      if (installationError && installationError.detail === 'No installation could be found on the job.') {
        await postJobInstallation({ id: job?.id });
      }
      await refetchJobInstallation();
      await refetchJob();
      setActive(EpcWizardSteps.INSTALL);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [installationError, job?.id, refetchJob, refetchJobInstallation, setActive]);

  return (
    <GenericNextStepsCard>
      <Button disabled={!job?.active} onClick={handleCreateInstallation} style="primary" id="book-installation-button">
        <p className="font-bold">Book installation</p>
      </Button>
    </GenericNextStepsCard>
  );
};

export default NextStepsCard;

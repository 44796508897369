import React, { useState } from 'react';
import { Button, Loading } from '@epcbuilder/lib/components';
import { Company } from '@epcbuilder/lib/models/companies';
import useCompanyUsers from '@/hooks/company/useCompanyUsers';
import AddUserToCompanyModal from './AddUserToCompanyModal';
import CompanyUsersHeader from './CompanyUsersHeader';
import CompanyUserTable from './CompanyUsersTable';

export const CompanyAdminData = ({ company, loading }: { company: Company; loading: boolean }) => {
  const { companyUsers, mutate: refetchCompanyUsers, isLoading } = useCompanyUsers(company.id);
  const [showAddUserModal, setAddUserButton] = useState<boolean>(false);

  return loading ? (
    <Loading />
  ) : (
    <div className="m-4">
      <div className="mt-4 flex w-full flex-row justify-between gap-4 sm:items-center">
        <div id="company-page-heading" className="flex flex-col text-left sm:flex-row sm:items-center">
          <h1 className="text-lg sm:text-xl">{company.companyName} -</h1>
          <h1 className="block text-lg sm:ml-2 sm:text-xl">User Management</h1>
        </div>
        <div className="w-36 justify-end">
          <Button id="add-new-button" onClick={() => setAddUserButton(true)}>
            Add User
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <CompanyUsersHeader />
        <div className="overflow-x-auto">
          <CompanyUserTable
            isLoading={isLoading}
            refetch={refetchCompanyUsers}
            company={company}
            users={companyUsers}
          />
        </div>
      </div>
      {showAddUserModal && <AddUserToCompanyModal company={company} onClose={() => setAddUserButton(false)} />}
    </div>
  );
};

export default CompanyAdminData;

import React from 'react';

export const HorizontalLine = () => {
  return (
    <div className="from-primary-dark via-primary via-secondary via-secondary-light to-secondary-dark h-1 w-full bg-gradient-to-r" />
  );
};

export const VerticalLine = () => {
  return (
    <div className="from-primary-dark via-primary via-secondary via-secondary-light to-secondary-dark h-full w-1 bg-gradient-to-b" />
  );
};

import { Property } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getProperties } from '@/network/properties';

const useProperties = ({
  currentEpcFilters,
  potentialEpcFilters,
  jobStatusFilters,
  search,
}: {
  currentEpcFilters: number[];
  potentialEpcFilters: number[];
  jobStatusFilters: number[];
  search: string;
}) => {
  const { data, error, mutate, isLoading } = useSWR<Property[]>(
    `properties-data-${currentEpcFilters}-${potentialEpcFilters}-${jobStatusFilters}-${search}`,
    async () =>
      await getProperties({
        currentEpcFilters,
        potentialEpcFilters,
        jobStatusFilters,
        search,
      })
  );

  return {
    properties: data,
    error,
    mutate,
    isLoading,
  };
};

export default useProperties;

import React from 'react';
import { Card } from '@epcbuilder/lib/components';
import PdfViewer from './PdfViewer';

const PdfReportCard = () => {
  return (
    <div className="flex flex-col gap-4 xl:flex-row">
      <div className="flex flex-col gap-2 xl:w-[202px] xl:gap-0 xl:pt-4">
        <p className="text-xl font-bold uppercase leading-none">
          Your personalised
          <br />
          property improvement plan
        </p>
        <div className="border-dark dark:border-light w-20 border-t-2 xl:w-14" />
      </div>
      <Card className="min-h-[220px] overflow-hidden will-change-transform lg:max-w-[320px] xl:min-h-[300px] xl:min-w-[500px]">
        <div
          className="border-primary h-[220px] w-full overflow-hidden rounded-[16px] border-2 xl:h-[300px]"
          id="pdf-viewer"
        >
          <PdfViewer />
        </div>
      </Card>
    </div>
  );
};

export default PdfReportCard;

import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@epcbuilder/lib/components';
import { Property } from '@epcbuilder/lib/models/properties';
import { PropertyAddressData, PropertyEpcData, PropertyStatusData } from './JobHistoryData';

const JobHistoryRow = ({ property }: { property: Property; refetch: () => void }) => {
  const navigate = useNavigate();
  const navigateToProperty = () => {
    navigate(`/properties/${property.id}`);
  };
  const navigateToProperyKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      navigateToProperty();
    }
  };
  return (
    <>
      <div
        id={property.jobId}
        className="border-neutral hover:bg-neutral-dark/10 flex min-h-20 w-full flex-row items-center justify-between border-b-2 px-4"
      >
        <div
          className="flex size-full min-h-20 flex-row gap-2"
          tabIndex={0}
          onClick={navigateToProperty}
          onKeyDown={navigateToProperyKeyPress}
          role="button"
        >
          <div className="flex flex-1 flex-row gap-2 p-2">
            <PropertyAddressData property={property} className="border-primary md:border-r-2" />
            <PropertyEpcData property={property} className="border-primary hidden md:flex xl:border-r-2" />
            <PropertyStatusData property={property} className="hidden xl:flex" />
          </div>
        </div>
        <div className="flex w-20 min-w-fit flex-row justify-center gap-4">
          <Link id={`${property.jobId}-view`} to={`/job-history/${property.jobId}`}>
            <IconButton style="secondary">
              <BiShow size={20} />
            </IconButton>
          </Link>
        </div>
      </div>
    </>
  );
};

export default JobHistoryRow;

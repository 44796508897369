import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { Button } from '@epcbuilder/lib/components';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import usePropertyRatings from '@/hooks/properties/usePropertyRatings';
import { EligibilityStatus, EpcWizardSteps } from '@/models/job';
import { postJobSurvey } from '@/network/jobs';
import GenericNextStepsCard from '../GenericNextStepsCard';

const NextStepsCard = ({
  setActive,
  setShowTenantCheckModal,
  setShowEligibilityModal,
  setShowConfirmEmailToTenantModal,
  jobEligibilityStatus,
  landlordManaged,
}: {
  setActive: Dispatch<SetStateAction<EpcWizardSteps>>;
  setShowTenantCheckModal: Dispatch<SetStateAction<boolean>>;
  setShowEligibilityModal: Dispatch<SetStateAction<boolean>>;
  setShowConfirmEmailToTenantModal: Dispatch<SetStateAction<boolean>>;
  jobEligibilityStatus: EligibilityStatus | undefined;
  landlordManaged: boolean;
}) => {
  const { property } = usePropertyContext();
  const { job, mutate: refetchJob } = useJobContext();
  const { jobSurvey, error: surveyError, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });
  const { propertyRatings } = usePropertyRatings({ id: property?.id });
  const { statuses } = usePropertyOwnerStatuses();

  const handleCreateSurvey = useCallback(async () => {
    try {
      if (!job?.id) throw Error;
      if (surveyError && surveyError.detail === 'No survey could be found on the job.') {
        await postJobSurvey({ id: job?.id });
      }
      await refetchJobSurvey();
      await refetchJob();
      setActive(EpcWizardSteps.ENERGY_ASSESSMENT);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job, surveyError, refetchJobSurvey, refetchJob, setActive]);

  const handleEligibility = useCallback(async () => {
    if (
      property?.ownerStatusId === statuses?.find((status) => status.name === 'Landlord / Managing Agent')?.id &&
      (property?.accessDetails.firstName === null ||
        property?.accessDetails.firstName === '' ||
        property?.accessDetails.lastName === null ||
        property?.accessDetails.lastName === '' ||
        property?.accessDetails.email === null ||
        property?.accessDetails.email === '' ||
        property?.accessDetails.phone === null ||
        property?.accessDetails.phone === '')
    ) {
      setShowTenantCheckModal(true);
      return;
    }

    if (landlordManaged) {
      setShowConfirmEmailToTenantModal(true);
    } else {
      setShowEligibilityModal(true);
    }
  }, [
    property,
    setShowEligibilityModal,
    setShowTenantCheckModal,
    setShowConfirmEmailToTenantModal,
    statuses,
    landlordManaged,
  ]);

  if (jobEligibilityStatus?.eligibility) {
    return (
      <GenericNextStepsCard>
        <div className="flex flex-col gap-2">
          <p className="w-full">Great news, it looks like your property is eligible for grant funding!</p>
          <p>One of our friendly team members will be in touch with you shortly to book your assessment.</p>
        </div>
      </GenericNextStepsCard>
    );
  } else {
    return (
      <GenericNextStepsCard>
        <div className="flex flex-col space-y-2">
          {!jobSurvey?.requestedBookingDate &&
            ((propertyRatings?.currentRating && ['A', 'B', 'C'].includes(propertyRatings?.currentRating)) ||
            (jobEligibilityStatus?.filledInForm && !jobEligibilityStatus?.eligibility) ? (
              <p className="w-full">Unfortunately, you're not eligible for free home upgrades.</p>
            ) : (
              <div>
                <MdInfoOutline size="24" className="tooltip-qualify absolute right-4 top-4 z-10" />
                <Tooltip anchorSelect=".tooltip-qualify" place="top-end" style={{ width: '250px' }}>
                  Check if you qualify for home upgrade grants or continue to Book Survey
                </Tooltip>
                <Button onClick={handleEligibility} disabled={jobEligibilityStatus?.filledInForm || !job?.active}>
                  <p className="font-bold underline">Do I qualify?</p>
                </Button>
              </div>
            ))}
          <Button
            onClick={() => {
              handleCreateSurvey();
              window.scrollTo({ top: 0, behavior: 'instant' });
            }}
            style={`${jobEligibilityStatus?.filledInForm || jobSurvey ? 'primary' : 'secondary'}`}
            disabled={jobEligibilityStatus?.eligibility || !job?.active}
          >
            <p className="font-bold">Book survey</p>
          </Button>
        </div>
      </GenericNextStepsCard>
    );
  }
};

export default NextStepsCard;

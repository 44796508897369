import React, { Dispatch, SetStateAction } from 'react';
import { EpcWizardSteps } from '@/models/job';
import NextStepsCard from './NextStepsCard';
import PdfDownloadCard from './PdfDownloadCard';
import PdfReportCard from './PdfReportCard';

const ImprovementPlanStep = ({ setActive }: { setActive: Dispatch<SetStateAction<EpcWizardSteps>> }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="grid justify-center gap-4 xl:max-w-[900px] xl:grid-cols-12">
        <div className="xl:col-span-9">
          <PdfReportCard />
        </div>
        <div className="flex flex-col justify-between gap-4 xl:col-span-3">
          <PdfDownloadCard />
          <NextStepsCard setActive={setActive} />
        </div>
      </div>
    </div>
  );
};

export default ImprovementPlanStep;

import { Company } from '@epcbuilder/lib/models/companies';
import useSWR from 'swr';
import { getCompanyFromUser } from '@/network/companies';

const useUserCompany = () => {
  const { data, error, mutate, isLoading } = useSWR<Company>(`user-company`, async () => await getCompanyFromUser());

  return {
    company: data,
    error,
    mutate,
    isLoading,
  };
};

export default useUserCompany;

import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdFilterList } from 'react-icons/md';
import { Accordion, IconButton } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { JobStatus } from '@epcbuilder/lib/models/jobs';
import { epcRatingMap, Property } from '@epcbuilder/lib/models/properties';
import usePropertyCount from '@/hooks/properties/usePropertyCount';
import usePropertyJobHistory from '@/hooks/properties/usePropertyJobHistory';
import { Filters } from '@/models/job';
import FiltersForm from './Filters';
import JobHistoryHeader from './JobHistoryHeader';
import JobHistoryTable from './JobHistoryTable';

const Properties = () => {
  const [filters, setFilters] = useState<boolean>(true);
  const [filteredProperties, setFilteredProperties] = useState<Property[]>([]);

  const defaultValues = {
    currentEpcFilters: [],
    potentialEpcFilters: [],
    search: '',
    showExpired: false,
    showCompleted: true,
  };

  const {
    register,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm<Filters>({ defaultValues });
  const currentEpcFilters = watch('currentEpcFilters');
  const potentialEpcFilters = watch('potentialEpcFilters');
  const search = watch('search');
  const showExpired = watch('showExpired');
  const showCompleted = watch('showCompleted');
  const isMobile = useMediaQuery(1023);

  const { properties, mutate: refetchProperties, isLoading } = usePropertyJobHistory();
  const { count, mutate: refetchPropertyCount } = usePropertyCount();

  const refetch = useCallback(() => {
    refetchProperties();
    refetchPropertyCount();
  }, [refetchProperties, refetchPropertyCount]);

  useEffect(() => {
    if (!properties) return;

    if (!showCompleted && !showExpired) {
      setFilteredProperties([]);
      return;
    }

    setFilteredProperties(
      properties?.filter((property) => {
        const isJobStatusMatch =
          (showExpired && property.jobStatus !== JobStatus.COMPLETE) ||
          (showCompleted && property.jobStatus === JobStatus.COMPLETE);

        const isEpcMatch =
          (currentEpcFilters.length === 0 && potentialEpcFilters.length === 0) ||
          currentEpcFilters.includes(epcRatingMap[property.currentEPCRating]) ||
          potentialEpcFilters.includes(epcRatingMap[property.potentialEPCRating]);

        const isSearchMatch =
          !search ||
          property.addressLine1.toLowerCase().includes(search.toLowerCase()) ||
          property.city.toLowerCase().includes(search.toLowerCase()) ||
          property.postcode.toLowerCase().includes(search.toLowerCase());

        return isJobStatusMatch && isEpcMatch && isSearchMatch;
      })
    );
  }, [currentEpcFilters, potentialEpcFilters, properties, search, showCompleted, showExpired]);

  return (
    <div>
      <Accordion
        accordionOpen={isMobile ? !filters : filters}
        accordionChildren={
          <FiltersForm control={control} register={register} errors={errors} reset={() => reset(defaultValues)} />
        }
      >
        <div className="flex w-full flex-row items-center justify-between">
          <h1 className="text-xl">Job History</h1>
          <div className="flex flex-1 flex-row items-center justify-end gap-4">
            <IconButton id="filters-button" style="secondary" onClick={() => setFilters(!filters)}>
              <MdFilterList size={20} />
            </IconButton>
          </div>
        </div>
      </Accordion>
      <JobHistoryHeader />
      <JobHistoryTable count={count} isLoading={isLoading} properties={filteredProperties} refetch={refetch} />
    </div>
  );
};

export default Properties;

import React from 'react';
import { InstallationStatus, JobInstallation } from '@epcbuilder/lib/models/jobs';
import { InstallationBooking } from '@/models/job';
import GenericNextStepsCard from '../GenericNextStepsCard';

const NextStepsCard = ({
  jobInstallation,
  installationBooking,
}: {
  jobInstallation?: JobInstallation;
  installationBooking?: InstallationBooking;
}) => {
  if (jobInstallation?.installationStatusId === InstallationStatus.INSTALLATION_COMPLETED) {
    return (
      <GenericNextStepsCard>
        <p className="font-bold">Congratulations! Your installation has been completed</p>
      </GenericNextStepsCard>
    );
  }

  if (installationBooking?.bookingDateTime) {
    return (
      <GenericNextStepsCard>
        <p className="font-bold">Sit tight until our team gets in touch on the day of your installation!</p>
      </GenericNextStepsCard>
    );
  }

  return (
    <GenericNextStepsCard>
      <p className="font-bold">Call to book your installation with us now!</p>
    </GenericNextStepsCard>
  );
};

export default NextStepsCard;

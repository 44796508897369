import React from 'react';
import Helmet from 'react-helmet';
import { JobHistory } from '@/components/job-history';
import Layout from '@/components/Layout';
import withAuth from '@/hoc/withAuth';

const JobHistoryPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Job History">
        <JobHistory />
      </Layout>
    </>
  );
};

export default withAuth(JobHistoryPage);

import React from 'react';
import { ErrorBoundary, initializeAppInsights } from '@epcbuilder/lib/components';
import { AuthProvider } from '@epcbuilder/lib/context/authContext';
import { ThemeProvider, useTheme } from '@epcbuilder/lib/context/themeContext';
import { ToastProvider } from '@epcbuilder/lib/context/toastContext';
import useClarity from '@epcbuilder/lib/hooks/useClarity';
import { initApi } from '@epcbuilder/lib/utils/api';
import { SWRConfig } from 'swr';
import Routes from './routes';
import '@epcbuilder/lib/styles/globals.css';

initApi(import.meta.env.VITE_API_URL);
if (import.meta.env.NODE_ENV !== 'development') {
  initializeAppInsights({ instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_INSTRUMENTATION_KEY });
}

const App = () => {
  const { theme } = useTheme();

  useClarity();

  return (
    <ToastProvider theme={theme}>
      <ErrorBoundary>
        <SWRConfig
          value={{
            shouldRetryOnError: false,
            revalidateOnFocus: false,
          }}
        >
          <ThemeProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </ThemeProvider>
        </SWRConfig>
      </ErrorBoundary>
    </ToastProvider>
  );
};

export default App;

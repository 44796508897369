import React from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { Card } from '@epcbuilder/lib/components';
import { PropertyRating } from '@epcbuilder/lib/models/properties';
import { format, isBefore } from 'date-fns';

const PropertyDetailsCard = ({ propertyRatings }: { propertyRatings: PropertyRating | undefined }) => {
  return (
    <Card>
      <div className="flex flex-col lg:gap-2">
        <div className="flex flex-row lg:flex-col">
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left ">
            <p className="text-sm font-bold lg:flex-[3] lg:text-base lg:font-normal">Current EPC:</p>
            <p className="text-lg font-bold lg:flex-[2] lg:text-xl" id="currentRating">
              {propertyRatings?.currentRating || 'N/A'}
            </p>
          </div>
          <div className="from-blue/0 via-blue to-blue/0 h-full w-1 bg-gradient-to-b lg:hidden" />
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left ">
            <p className="text-sm font-bold lg:flex-[3] lg:text-base lg:font-normal">Potential EPC:</p>
            <p className="text-lg font-bold lg:flex-[2] lg:text-xl" id="potentialRating">
              {propertyRatings?.potentialRating || 'N/A'}
            </p>
          </div>
        </div>
        <div className="from-blue/0 via-blue to-blue/0 hidden h-1 w-full bg-gradient-to-r lg:block" />
        <div className="flex flex-row lg:flex-col">
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left">
            <p className="text-base lg:flex-[3]">Inspection date:</p>
            <p className="text-base font-bold lg:flex-[2]" id="ratingCreatedOn">
              {propertyRatings?.ratingCreatedOn
                ? format(new Date(propertyRatings?.ratingCreatedOn), 'dd/MM/yyyy')
                : 'N/A'}
            </p>
          </div>
          <div
            className={`${propertyRatings?.certificateValidUntil && isBefore(new Date(propertyRatings?.certificateValidUntil), new Date()) && 'bg-error-light/30'} relative flex flex-1 flex-col rounded-[18px] p-2 text-center lg:flex-row lg:items-center lg:text-left`}
          >
            <p className="text-base lg:flex-[3]">Valid until:</p>
            <p className="text-base font-bold lg:flex-[2]" id="certificateValidUntil">
              {propertyRatings?.certificateValidUntil
                ? format(new Date(propertyRatings?.certificateValidUntil), 'dd/MM/yyyy')
                : 'N/A'}
            </p>
            {propertyRatings?.certificateValidUntil &&
              isBefore(new Date(propertyRatings?.certificateValidUntil), new Date()) && (
                <div className="bg-error-light absolute -right-3 rounded-full p-1">
                  <MdOutlineErrorOutline className="text-white" size={22} />
                </div>
              )}
          </div>
        </div>
        <div className="from-blue/0 via-blue to-blue/0 h-1 w-full bg-gradient-to-r" />
        <div className="flex flex-row lg:flex-col">
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left">
            <p className="text-base lg:flex-[3]">Property type:</p>
            <p className="text-base font-bold lg:flex-[2]" id="propertyType">
              {propertyRatings?.propertyType || 'N/A'}
            </p>
          </div>
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left">
            <p className="text-base lg:flex-[3]">Build form:</p>
            <p className="text-base font-bold lg:flex-[2]" id="builtForm">
              {propertyRatings?.builtForm || 'N/A'}
            </p>
          </div>
        </div>
        <div className="from-blue/0 via-blue to-blue/0 h-1 w-full bg-gradient-to-r" />
        <div className="flex flex-row lg:flex-col">
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left">
            <p className="text-base lg:flex-[3]">Current Co2:</p>
            <p className="text-base font-bold lg:flex-[2]" id="co2EmissionsCurrent">
              {propertyRatings?.co2EmissionsCurrent || 'N/A'}
            </p>
          </div>
          <div className="flex flex-1 flex-col p-2 text-center lg:flex-row lg:items-center lg:text-left">
            <p className="text-base lg:flex-[3] lg:text-lg">Potential Co2:</p>
            <p className="text-base font-bold lg:flex-[2] lg:text-lg" id="co2EmissionsPotential">
              {propertyRatings?.co2EmissionsPotential || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PropertyDetailsCard;

import React from 'react';
import { Loading } from '@epcbuilder/lib/components';
import { Company } from '@epcbuilder/lib/models/companies';
import { User } from '@epcbuilder/lib/models/user';
import CompanyUserRow from './CompanyUsersRow';

const CompanyUserTable = ({
  isLoading,
  company,
  users,
  refetch,
}: {
  isLoading: boolean;
  company: Company;
  users: User[] | undefined;
  refetch: () => void;
}) => {
  if (isLoading || !users) {
    return <Loading id="company-users-loading" />;
  }

  if (users.length === 0) {
    return (
      <p id="properties-count-empty" className="my-8 flex items-center justify-center">
        No users attached to this company
      </p>
    );
  }

  return (
    <div id="properties-table">
      {users.map((user) => (
        <CompanyUserRow key={user.id} company={company} user={user} refetch={refetch} />
      ))}
    </div>
  );
};

export default CompanyUserTable;

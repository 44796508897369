import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from '@epcbuilder/lib/components';
import { TenantEcoFormStatus, TenantEmailStatus } from '@epcbuilder/lib/models/properties';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { format } from 'date-fns';
import { postSendTenantEligibility } from '@/network/jobs';

const TenantEmailStatusItem = ({ status }: { status: TenantEmailStatus }) => {
  const getTextFromEmailStatus = (status: TenantEcoFormStatus): string => {
    switch (status) {
      case TenantEcoFormStatus.NOT_OPENED:
        return 'The ECO form has not been opened.';
      case TenantEcoFormStatus.OPENED:
        return 'The ECO form has been opened but not completed.';
      case TenantEcoFormStatus.COMPLETE_ELIGIBLE:
        return 'The ECO form has been completed and tenant is eligible.';
      case TenantEcoFormStatus.COMPLETE_INELIGIBLE:
        return 'The ECO form has been completed and tenant is not eligible.';
      default:
        return 'Unexpected status.';
    }
  };

  return (
    <div>
      <p>
        {format(new Date(status.emailSentAt), 'dd/MM/yyyy')} - {status.recipientAddress}
      </p>
      <p>{getTextFromEmailStatus(status.tenantFormStatus)}</p>
    </div>
  );
};

const TenantEmailStatusContainer = ({ tenantEmailStatuses }: { tenantEmailStatuses: TenantEmailStatus[] }) => {
  return (
    <div className="flex flex-col space-y-2">
      {tenantEmailStatuses.map((tes, idx) => (
        <TenantEmailStatusItem key={idx} status={tes} />
      ))}
    </div>
  );
};

const ConfirmEmailToTenantModal = ({
  jobId,
  tenantEmailStatuses,
  onClose,
}: {
  jobId: string;
  tenantEmailStatuses?: TenantEmailStatus[];
  onClose: () => void;
}) => {
  const [sendingEmailRequest, setSendingEmailRequest] = useState<boolean>(false);
  const [messageToTenant, setMessageToTenant] = useState('');
  const hasBeenEmailed = tenantEmailStatuses && tenantEmailStatuses.length > 0;

  const topText = hasBeenEmailed ? 'Your tenant has been notified.' : 'Your tenant has not been notified yet.';

  const bottomText = hasBeenEmailed
    ? 'Use the button below to re-send the ECO form to the address listed in tenant details.'
    : 'Use the button below to send the ECO form to the address listed in tenant details.';

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col gap-4">
        <h2>{topText}</h2>
        {hasBeenEmailed && <TenantEmailStatusContainer tenantEmailStatuses={tenantEmailStatuses} />}
        <p>{bottomText}</p>
        <p>You can also add a message for your tenant using the below box.</p>
        <textarea
          className="text-dark h-24 resize-none rounded-[8px] p-2 text-left font-medium outline-none"
          onChange={(event) => {
            setMessageToTenant(event.target.value);
          }}
        />
        <Button
          onClick={async () => {
            try {
              setSendingEmailRequest(true);
              await postSendTenantEligibility({ id: jobId, message: messageToTenant });
              setSendingEmailRequest(false);
            } catch (error: unknown) {
              setSendingEmailRequest(false);
              handleUnknownDetail(error);
              return;
            }
            toast.success('Tenant has been emailed');
            onClose();
          }}
          style="primary"
          loading={sendingEmailRequest}
        >
          Email tenant
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmEmailToTenantModal;

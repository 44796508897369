import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from '@epcbuilder/lib/components';
import { useJobContext } from '@/context/useJobContext';
import { postJobEligibility } from '@/network/jobs';

const TenantCheckModal = ({
  setShowEligibilityErrorModal,
  setShowPreTenantDetailsModal,
  setTenantCheck,
  onClose,
}: {
  setShowEligibilityErrorModal: Dispatch<SetStateAction<boolean>>;
  setShowPreTenantDetailsModal: Dispatch<SetStateAction<boolean>>;
  setTenantCheck: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  const { job } = useJobContext();
  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col gap-4">
        <p>Is the property currently occupied by a tenant?</p>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => {
              setTenantCheck(true);
              setShowPreTenantDetailsModal(true);
              onClose();
            }}
            style="secondary"
          >
            Yes
          </Button>
          <Button
            onClick={async () => {
              setTenantCheck(false);
              await postJobEligibility({
                id: job?.id,
                data: {
                  marketingConsent: false,
                  datamatchConsent: false,
                  eligible: false,
                  flexCriteria: ['NoneOfTheAbove'],
                  benefits: ['noneoftheabove'],
                  incomeLess31k: false,
                },
              });
              setShowEligibilityErrorModal(true);
              onClose();
            }}
            style="secondary"
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TenantCheckModal;

import React from 'react';
import { toast } from 'react-toastify';
import { Company } from '@epcbuilder/lib/models/companies';
import useUser from '@/hooks/auth/useUser';
import useCompanyPermissions from '@/hooks/company/useCompanyPermissions';
import useJoinRequests from '@/hooks/company/useJoinRequests';
import CompanyAdminData from './CompanyAdmin';
import CompanyRequestData from './CompanyRequests';
import CompanyUserData from './CompanyUser';
import RequestNewCompany from './RequestNewCompany';

export const CompanyScreen = ({ company, error }: { company: Company; error: Error }) => {
  const user = useUser();
  const { permissions, isLoading } = useCompanyPermissions(company.id);
  const { requests, mutate: refetchCompanyJoinRequests, isLoading: joinRequestsLoading } = useJoinRequests();

  {
    error && toast.error(error.message, { toastId: 'company-load-failure' });
  }

  if (user.user?.companyId != '-1' || !user.user?.companyId) {
    if (permissions?.isAdminUser) {
      return <CompanyAdminData loading={isLoading} company={company} />;
    } else {
      return <CompanyUserData loading={isLoading} company={company} />;
    }
  } else if (requests == undefined || requests?.length == 0) {
    return <RequestNewCompany />;
  } else {
    return (
      <CompanyRequestData
        companyRequests={requests}
        isLoading={joinRequestsLoading}
        refetch={refetchCompanyJoinRequests}
      />
    );
  }
};

export default CompanyScreen;

import { CompanyJoinRequest } from '@epcbuilder/lib/models/companies';
import useSWR from 'swr';
import { getJoinRequests } from '@/network/companies';

const useJoinRequests = () => {
  const { data, error, mutate, isLoading } = useSWR<CompanyJoinRequest[]>(
    `join-requests`,
    async () => await getJoinRequests()
  );

  return {
    requests: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJoinRequests;

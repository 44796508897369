import React from 'react';
import { CompanyJoinRequest } from '@epcbuilder/lib/models/companies';
import { KeyedMutator } from 'swr';
import CompanyRequestsHeader from './CompanyRequestsHeader';
import CompanyRequestsTable from './CompanyRequestsTable';

export const CompanyRequestData = ({
  companyRequests,
  isLoading,
  refetch,
}: {
  companyRequests: CompanyJoinRequest[] | undefined;
  isLoading: boolean;
  refetch: KeyedMutator<CompanyJoinRequest[]>;
}) => {
  return (
    <div className="m-4">
      <div className="mb-9 flex w-full flex-row items-center justify-between">
        <h1 id="company-request-heading" className="text-xl">
          Company Requests
        </h1>
      </div>
      <CompanyRequestsHeader />
      <CompanyRequestsTable isLoading={isLoading} refetch={refetch} requests={companyRequests} />
    </div>
  );
};

export default CompanyRequestData;

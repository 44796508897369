import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';

type TooltipProps = {
  id: string;
  text: string;
  size?: number;
  iconClasses?: string;
};

export const CardTooltip = (props: TooltipProps) => {
  return (
    <TooltipWithIcon
      {...props}
      iconClasses={`${props.iconClasses} ? ${props.iconClasses} : absolute right-4 top-4 z-10`}
    />
  );
};

export const TooltipWithIcon = ({ id, text, size, iconClasses }: TooltipProps) => {
  const defaultSize = 24;

  return (
    <>
      <MdInfoOutline size={size ?? defaultSize} id={id} className={`${iconClasses}`} />
      <Tooltip anchorSelect={`#${id}`} place="top-end" style={{ width: '250px' }}>
        {text}
      </Tooltip>
    </>
  );
};

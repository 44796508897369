import { enumToKeyValueArray, Option } from '../utils/generic';
import { JobStatus } from './jobs';

export type PropertyAccessDetails = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  notes?: string;
};

export type AdminContactEmail = {
  propertyId: string;
  recipientEmail: string;
  emailSubject: string;
  emailBody: string;
};

export type Property = {
  id: string;
  userID: string;
  userIconColourHex: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postcode: string;
  statusUpdatedOn: Date;
  isDeleted: boolean;
  createdOn: Date;
  currentEPCRating: EpcRating;
  potentialEPCRating: EpcRating;
  hasEPC: boolean;
  accessDetails: PropertyAccessDetails;
  status: PropertyStatus;
  isMeesCompliant: boolean;
  userFullName?: string;
  ownerStatusId?: number;
  jobStatus?: JobStatus;
  jobId?: string;
  eligible: boolean;
  inEligibleInstallationArea: boolean;
};

export type PropertyRating = {
  id: string;
  ratingCreatedOn: Date;
  currentRating: string;
  potentialRating: string;
  currentEnergyEfficiency: number;
  potentialEnergyEfficiency: number;
  lmkKey: string;
  certificateValidUntil: Date;
  builtForm: string;
  constructionAgeBand: string;
  wallDescription: string;
  floorDescription: string;
  propertyType: string;
  co2EmissionsCurrent: string;
  co2EmissionsPotential: string;
  isDeleted: boolean;
  createdOn: Date;
  status: PropertyStatus;
  isManualEntry?: boolean;
  certificateNumber?: string;
};

export type CreateAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postcode: string;
  ownerStatusId: number;
  bypassEPC?: boolean;
};

export type PostcodeSearch = {
  postcode: string;
};

export type AddressResult = {
  description: string;
  highlight: string;
  id: string;
  text: string;
  type: string;
};

export type Address = {
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  bypassEPC?: boolean;
};

export type PropertyOwnerStatuses = {
  id: string;
  name: string;
  isActive: boolean;
};

export enum EpcRating {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
  F = 6,
  G = 7,
  NONE = 8,
}

export const epcRatingMap: { [key: string]: number } = {
  A: EpcRating.A,
  B: EpcRating.B,
  C: EpcRating.C,
  D: EpcRating.D,
  E: EpcRating.E,
  F: EpcRating.F,
  G: EpcRating.G,
  NONE: EpcRating.NONE,
};

export const epcRatingOptions: Option<string | EpcRating>[] = enumToKeyValueArray(EpcRating);

export enum PropertyStatus {
  NEW = 1,
  COMPLIANT = 2,
  NON_COMPLIANT = 3,
  REQUESTED_EPC = 4,
  BOOKED_EPC = 5,
}

export const statusOptions: Option<string | PropertyStatus>[] = enumToKeyValueArray(PropertyStatus);

export enum TenantEcoFormStatus {
  NOT_OPENED = 1,
  OPENED = 2,
  COMPLETE_ELIGIBLE = 3,
  COMPLETE_INELIGIBLE = 4,
}

export type TenantEmailStatus = {
  emailSentAt: Date;
  recipientAddress: string;
  tenantFormStatus: number;
};

import { TenantEmailStatus } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getJobTenantEmailStatuses as getJobTenantEmailStatuses } from '@/network/jobs';

const useJobTenantEmailStatus = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<TenantEmailStatus[]>(
    id ? `job-tenant-email-status-${id}` : null,
    async () => (id ? await getJobTenantEmailStatuses({ id }) : null)
  );

  return {
    tenantEmailStatuses: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobTenantEmailStatus;

import { User } from '@epcbuilder/lib/models/user';
import useSWR from 'swr';
import { getUser } from '@/network/profile';

const useUser = () => {
  const { data, isLoading, error } = useSWR<User>('user-data', async () => await getUser());

  return {
    user: data,
    isLoading,
    error,
  };
};

export default useUser;

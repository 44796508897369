import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, TextInput } from '@epcbuilder/lib/components';
import { Company, SendUserRequest } from '@epcbuilder/lib/models/companies';
import { sendCompanyUserRequest } from '@/network/companies';

const AddUserToCompanyModal = ({ company, onClose }: { company: Company; onClose: () => void }) => {
  const { register, watch } = useForm<SendUserRequest>({
    reValidateMode: 'onSubmit',
  });
  const email = watch('email');

  const handleCompanyRequest = useCallback(async () => {
    try {
      await sendCompanyUserRequest({ email: email, companyid: company.id });
      toast.success('Successfully sent join request', { toastId: 'invite-success' });
    } catch (error: unknown) {
      toast.error('There was a problem sending the join request. Please try again.');
    }
  }, [company.id, email]);

  return (
    <Modal id="add-user-to-company-modal" onClose={onClose}>
      <h1>Send join request</h1>
      <p>Send a request for a user to join your company.</p>
      <p>You will be notified when they accept.</p>
      <div className="mt-4 w-full sm:self-end">
        <TextInput
          {...register('email')}
          type="text"
          id="email"
          value={email}
          name="email"
          title="Enter users email address"
          placeholder="Enter email address"
        />
      </div>
      <div className="mt-4 flex flex-row justify-between gap-4">
        <div className="w-48">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="w-48">
          <Button
            id="add-confirm"
            style="primary"
            onClick={async () => {
              handleCompanyRequest();
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserToCompanyModal;

import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FullPage, Loading, Modal } from '@epcbuilder/lib/components';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import useJobEligibilityStatus from '@/hooks/jobs/useJobEligibilitystatus';
import useJobTenantEmailStatus from '@/hooks/jobs/useJobTenantEmailStatus';
import usePropertyRatings from '@/hooks/properties/usePropertyRatings';
import { EpcWizardSteps } from '@/models/job';
import AccessDetailsModal from './AccessDetailsModal';
import ConfirmEmailToTenantModal from './ConfirmEmailToTenantModal';
import EligibleErrorModal from './EligibleErrorModal';
import EligibleSuccessModal from './EligibleSuccessModal';
import EligibilityModal from './EligiblityModal';
import EpcGraphCard from './EpcGraphCard';
import NextStepsCard from './NextStepsCard';
import PreTenantDetailsModal from './PreTenantDetailsModal';
import PropertyDetailsCard from './PropertyDetailsCard';
import TenantCheckModal from './TenantCheckModal';
import TenantDetailsCard from './TenantDetailsCard';

const EpcRatingStep = ({ setActive }: { setActive: Dispatch<SetStateAction<EpcWizardSteps>> }) => {
  const navigate = useNavigate();
  const { property } = usePropertyContext();
  const {
    propertyRatings,
    error: propertyRatingError,
    isLoading: propertyRatingLoading,
  } = usePropertyRatings({ id: property?.id });
  const { job } = useJobContext();
  const { jobEligibilityStatus, mutate: refetchJobEligibilityStatus } = useJobEligibilityStatus({ id: job?.id });
  const { statuses } = usePropertyOwnerStatuses();
  const { tenantEmailStatuses, mutate: refetchJobTenantEmailStatus } = useJobTenantEmailStatus({ id: job?.id });

  const [showUpdateAccessDetailsModal, setShowUpdateAccessDetailsModal] = useState<boolean>(false);
  const [showEligibilityModal, setShowEligibilityModal] = useState<boolean>(false);
  const [showEligibilityErrorModal, setShowEligibilityErrorModal] = useState<boolean>(false);
  const [showEligibilitySuccessModal, setShowEligibilitySuccessModal] = useState<boolean>(false);
  const [showTenantCheckModal, setShowTenantCheckModal] = useState<boolean>(false);
  const [tenantCheck, setTenantCheck] = useState<boolean>(false);
  const [showPreTenantDetailsModal, setShowPreTenantDetailsModal] = useState<boolean>(false);
  const [showConfirmEmailToTenantModal, setShowConfirmEmailToTenantModal] = useState<boolean>(false);
  const [proceedToEmailTenantModal, setProceedToEmailTenantModal] = useState<boolean>(false);

  const landlordManaged = useMemo(
    () => property?.ownerStatusId === statuses?.find((status) => status.name === 'Landlord / Managing Agent')?.id,
    [property, statuses]
  );

  if (propertyRatingLoading) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="grid justify-center gap-4 lg:max-w-[640px] lg:grid-cols-2 xl:max-w-[900px] xl:grid-cols-11">
          <div className="flex w-full flex-col items-center lg:order-1 xl:col-span-4 xl:row-span-2">
            <EpcGraphCard propertyRatings={propertyRatings} propertyRatingError={propertyRatingError} />
          </div>
          <div className="w-full lg:order-2 xl:order-first xl:col-span-4 xl:row-span-2">
            <PropertyDetailsCard propertyRatings={propertyRatings} />
          </div>
          <div className="flex w-full items-center gap-4 lg:order-3 lg:flex-row xl:col-span-3 xl:flex-col">
            <TenantDetailsCard
              setShowUpdateAccessDetailsModal={setShowUpdateAccessDetailsModal}
              setProceedToEmailTenantModal={setProceedToEmailTenantModal}
              landlordManaged={landlordManaged}
            />
          </div>
          <div className="w-full lg:order-4 xl:col-span-3">
            <NextStepsCard
              setActive={setActive}
              setShowTenantCheckModal={setShowTenantCheckModal}
              setShowEligibilityModal={setShowEligibilityModal}
              setShowConfirmEmailToTenantModal={setShowConfirmEmailToTenantModal}
              jobEligibilityStatus={jobEligibilityStatus}
              landlordManaged={landlordManaged}
            />
          </div>
        </div>
      </div>
      {showTenantCheckModal && (
        <TenantCheckModal
          onClose={() => setShowTenantCheckModal(false)}
          setShowEligibilityErrorModal={setShowEligibilityErrorModal}
          setShowPreTenantDetailsModal={setShowPreTenantDetailsModal}
          setTenantCheck={setTenantCheck}
        />
      )}
      {showUpdateAccessDetailsModal && (
        <AccessDetailsModal
          eligiblityCheck={tenantCheck}
          landlordManaged={landlordManaged}
          proceedToEmailTenantModal={proceedToEmailTenantModal}
          setShowEligibilityModal={setShowEligibilityModal}
          setShowConfirmEmailToTenantModal={setShowConfirmEmailToTenantModal}
          onClose={() => {
            setShowUpdateAccessDetailsModal(false);
            setTenantCheck(false);
          }}
        />
      )}
      {showEligibilityModal && (
        <EligibilityModal
          onClose={() => setShowEligibilityModal(false)}
          setShowEligibilitySuccessModal={setShowEligibilitySuccessModal}
          setShowEligibilityErrorModal={setShowEligibilityErrorModal}
        />
      )}
      {showEligibilityErrorModal && (
        <EligibleErrorModal
          onClose={() => setShowEligibilityErrorModal(false)}
          refetchJobEligibilityStatus={refetchJobEligibilityStatus}
        />
      )}
      {showEligibilitySuccessModal && (
        <EligibleSuccessModal
          onClose={() => setShowEligibilitySuccessModal(false)}
          refetchJobEligibilityStatus={refetchJobEligibilityStatus}
        />
      )}
      {showPreTenantDetailsModal && (
        <PreTenantDetailsModal
          setShowUpdateAccessDetailsModal={setShowUpdateAccessDetailsModal}
          setProceedToEmailTenantModal={setProceedToEmailTenantModal}
          onClose={() => setShowPreTenantDetailsModal(false)}
        />
      )}
      {showConfirmEmailToTenantModal && job && (
        <ConfirmEmailToTenantModal
          jobId={job.id}
          tenantEmailStatuses={tenantEmailStatuses}
          onClose={() => {
            refetchJobTenantEmailStatus();
            setShowConfirmEmailToTenantModal(false);
          }}
        />
      )}
      {property && !property.inEligibleInstallationArea && (
        <Modal id="ineligibleAreaModal" onClose={() => navigate(-1)}>
          <h1 className="pb-4">Sorry</h1>
          <p className="pb-2">
            We currently focus on providing support to customers within the Northwest, and some surrounding areas.
          </p>
          <p className="pb-4">We will be in touch as soon as we are able to support you.</p>
          <Button onClick={() => navigate(-1)}>Back</Button>
        </Modal>
      )}
    </>
  );
};

export default EpcRatingStep;

import React, { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import * as jose from 'jose';
import Cookies from 'js-cookie';
import { FullScreen, Loading } from '../components';
import { AuthContext } from '../context/authContext';
import { Token } from '../models/authContext';

const withAuthCallback = (
  WrappedComponent: FunctionComponent,
  callback: (data: { refreshToken: string }) => Promise<void | AxiosResponse | undefined>
) => {
  const AuthComponent: FunctionComponent = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state, dispatchLogin, dispatchLogout } = useContext(AuthContext);
    const { isAuthenticated } = state;
    const rememberMeToken = Cookies.get('rememberMeToken');

    const handleLogin = useCallback(
      (token: string, refreshToken: string) => {
        const payload = jose.decodeJwt(token) as Token;
        dispatchLogin({
          token,
          refreshToken,
          rememberMe: !!rememberMeToken,
          userId: payload.ID,
        });
      },
      [dispatchLogin, rememberMeToken]
    );

    const checkAuth = useCallback(
      async (refreshToken: string) => {
        try {
          const response = await callback({ refreshToken });
          if (response?.status === 401) {
            throw new Error();
          } else {
            handleLogin(response?.data.token, response?.data.refreshToken);
          }
        } catch (err: unknown) {
          dispatchLogout();
          navigate(`/${location.pathname && `?redirect=${location.pathname}`}`);
        }
      },
      [dispatchLogout, handleLogin, navigate, location]
    );

    const checkValidToken = useCallback(() => {
      const token = sessionStorage.getItem('token');
      const refreshToken = sessionStorage.getItem('refreshToken');
      if (token && refreshToken) {
        const now = new Date().getTime() / 1000;
        const { exp = 0 } = jose.decodeJwt(token) as Token;
        const minutesUntilExpiration = (exp - now) / 60;

        if (minutesUntilExpiration <= 5) {
          if (refreshToken) {
            checkAuth(refreshToken);
          }
        } else {
          handleLogin(token, refreshToken);
        }
      } else {
        if (rememberMeToken) {
          checkAuth(rememberMeToken);
        } else {
          navigate(`/${location.pathname && `?redirect=${location.pathname}`}`);
        }
      }
    }, [checkAuth, handleLogin, navigate, location, rememberMeToken]);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          checkValidToken();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [checkValidToken]);

    useEffect(() => {
      if (!isAuthenticated) {
        checkValidToken();
      }
    }, [checkValidToken, isAuthenticated]);

    if (!isAuthenticated) {
      return (
        <FullScreen id="authenticating">
          <Loading />
        </FullScreen>
      );
    }

    return <WrappedComponent {...props} />;
  };

  return AuthComponent;
};

export default withAuthCallback;

import React from 'react';
import { HorizontalLine, Logo, ThemeToggle } from '@epcbuilder/lib/components';

const TopBar = () => {
  return (
    <div className="fixed left-0 top-0 z-20 w-screen shadow">
      <div className="bg-color flex h-12 w-full flex-row items-center justify-between gap-4 px-4 lg:h-24">
        <div className="relative w-24 lg:w-48">
          <Logo type="color" className="self-end" href="/properties" />
        </div>
        <div className="flex flex-row items-center justify-between gap-4">
          <ThemeToggle />
        </div>
      </div>
      <HorizontalLine />
    </div>
  );
};

export default TopBar;

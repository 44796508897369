import React from 'react';

const CompanyRequestsHeader = () => {
  return (
    <div className="border-neutral hidden min-h-12 w-full flex-row items-center justify-between border-b-2 px-4 md:flex">
      <div className="flex size-full min-h-12 flex-row gap-2">
        <div className="flex flex-1 flex-row gap-2 p-2">
          <div className={`mr-4 min-w-fit flex-1`}>
            <p className="flex h-full w-64 flex-row items-center gap-4">Company Name</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
            <p className="flex h-full w-64 flex-row items-center gap-4">Requested By</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
            <p className="flex h-full w-72 flex-row items-center gap-4"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyRequestsHeader;

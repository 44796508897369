import React from 'react';
import { Card } from '@epcbuilder/lib/components';
import { Job } from '@epcbuilder/lib/models/jobs';
import { InstallationBooking } from '@/models/job';

const ChosenMeasuresCard = ({ job, installationBooking }: { job?: Job; installationBooking?: InstallationBooking }) => {
  return (
    <Card className="lg:max-w-none">
      {!installationBooking?.bookingDateTime && (
        <div className="bg-light/60 dark:bg-dark/60 absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]">
          <div className="bg-blue/90 dark:bg-primary-dark/90 max-w-[220px] rounded-[22px] p-4 shadow-lg">
            <p className="text-center font-bold">Call us to select your chosen quote and book your installation now!</p>
          </div>
        </div>
      )}
      <div className="relative flex flex-col gap-4">
        <p className="text-xl font-bold leading-none">Chosen measures and cost breakdown</p>
        <div className="border-neutral dark:border-neutral/30 gap-4 border-t-2" />
        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="border-neutral dark:border-neutral/30 flex h-[300px] w-[288px] max-w-[288x] flex-col gap-4 rounded-[16px] border-2 border-solid px-4 py-6 lg:h-[380px] lg:w-[440px] lg:max-w-[440px]">
            {installationBooking?.selectedImprovement?.items.map((improvementItem) => (
              <div
                key={`improvement-item-${improvementItem.id}`}
                className={`${improvementItem?.improvementItemType?.isDiscount ? 'text-[#008080]' : ''} flex w-full flex-row justify-between`}
              >
                <p className="flex-1 font-bold">{improvementItem.itemText}</p>
                <p className="font-bold">
                  {improvementItem.itemCost
                    ? `${improvementItem.improvementItemType?.isDiscount ? '-' : ''} £${improvementItem.itemCost}`
                    : '£0'}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-4 lg:flex-col">
            <div className="bg-blue dark:bg-dark flex size-[90px] flex-col items-center justify-center gap-2 rounded-[16px] text-center lg:h-[115px] lg:w-[157px]">
              <p className="font-bold">Current EPC rating</p>
              <p className="text-2xl font-bold">{job?.currentEpc || 'n/a'}</p>
            </div>
            <div className="bg-blue dark:bg-dark flex size-[90px] flex-col items-center justify-center gap-2 rounded-[16px] text-center lg:h-[115px] lg:w-[157px]">
              <p className="font-bold">New EPC rating</p>
              <p className="text-2xl font-bold">{installationBooking?.selectedImprovement?.newEpc || 'n/a'}</p>
            </div>
            <div className="bg-blue dark:bg-dark flex size-[90px] flex-col place-content-around items-center gap-2 rounded-[16px] lg:h-[115px] lg:w-[157px] lg:justify-center">
              <p className="mt-2 font-bold lg:mt-0">Total cost</p>
              <p
                className={`mt-2 ${
                  installationBooking?.selectedImprovement?.cost &&
                  installationBooking!.selectedImprovement!.cost.toString().length > 4
                    ? 'text-xl lg:text-2xl'
                    : 'text-2xl'
                } font-bold lg:mt-0`}
              >
                {installationBooking?.selectedImprovement?.cost
                  ? `£${installationBooking?.selectedImprovement.cost}`
                  : '£0'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ChosenMeasuresCard;

import React from 'react';
import { toast } from 'react-toastify';
import { Button } from '@epcbuilder/lib/components';
import { AxiosErrorData } from '@epcbuilder/lib/utils/api';
import { TextField } from '@mui/material';
import { newCompanyRequest } from '@/network/companies';

export const RequestNewCompany = () => {
  const [formValues, setFormValues] = React.useState({
    companyName: '',
    adminEmail: '',
  });

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [name]: event.target.value });
  };

  const submitNewCompanyRequest = async () => {
    try {
      await newCompanyRequest({ companyName: formValues.companyName, adminEmail: formValues.adminEmail });
      toast.success('Company request successfully submitted', { toastId: 'new-company-request-sucess' });
    } catch (error: unknown) {
      const { detail } = error as AxiosErrorData;

      switch (detail) {
        case 'Admin email is not attached to an EPC builder user':
          toast.error('The requested admin email could not be matched to an existing EPC builder user.');
          break;
        case 'Admin email is associated with a company already':
          toast.error('The requested admin email is already associated with a company');
          break;
        default:
          toast.error('An error occured when submitting new company request. Please try again later');
          break;
      }
    }
  };

  return (
    <div className="ml-[10%] mt-12 w-[80%] md:ml-[20%] md:w-[60%]">
      <div className="mb-9 w-full items-center justify-between">
        <h1 className="text-xl uppercase underline">Register Your Company</h1>
        <p className="pt-4">
          Are you using EPC Builder at work or as part of a team? Fill in the below to set yourself up as a company:
          from here you’ll be able to invite other users from EPC Builder to your team and start working through your
          properties together!
        </p>
      </div>
      <div className="relative rounded-3xl shadow-2xl">
        <img className="mb-6 w-full rounded-3xl" src="/images/company-image.png" alt="" />
      </div>
      <div className="w-full items-center justify-between gap-8 rounded-xl bg-[#F7FFFD]/85 px-10 py-5 shadow-2xl md:flex">
        <div className="w-full md:w-2/5">
          <TextField
            sx={{
              '& .MuiInputLabel-root.Mui-focused': { color: '#121212' },
              '& .MuiOutlinedInput-root.Mui-focused fieldset': { borderColor: '#121212' },
            }}
            id="companyNameInput"
            size="small"
            variant="outlined"
            label="Company Name"
            fullWidth={true}
            onChange={handleChange('companyName')}
          />
        </div>
        <div className="my-5 w-full md:my-0 md:w-3/5">
          <TextField
            sx={{
              '& .MuiInputLabel-root.Mui-focused': { color: '#121212' },
              '& .MuiOutlinedInput-root.Mui-focused fieldset': { borderColor: '#121212' },
            }}
            id="emailInput"
            size="small"
            type="email"
            variant="outlined"
            label="Admin Email Address"
            fullWidth={true}
            onChange={handleChange('adminEmail')}
          />
        </div>
        <Button onClick={submitNewCompanyRequest} className="w-full px-10 md:w-1/5">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default RequestNewCompany;

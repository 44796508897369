import React from 'react';
import { Card } from '@epcbuilder/lib/components/Layout';
import { Children } from '@epcbuilder/lib/utils';

const GenericNextStepsCard = ({ children, tooltipText }: { children: Children; tooltipText?: string }) => {
  return (
    <Card tooltipText={tooltipText} className="border-primary border-2">
      <div>
        <p className="pb-2 font-bold italic">
          Your <span className="text-primary">next steps:</span>
        </p>
        {children}
      </div>
    </Card>
  );
};

export default GenericNextStepsCard;

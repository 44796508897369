import useSWR from 'swr';
import { InstallationBooking } from '@/models/job';
import { getInstallationBooking } from '@/network/installations';

const useInstallationBooking = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<InstallationBooking>(
    id ? `installation-booking-${id}` : null,
    async () => (id ? await getInstallationBooking({ id }) : null)
  );

  return {
    installationBooking: data,
    error,
    mutate,
    isLoading,
  };
};

export default useInstallationBooking;

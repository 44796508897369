import { api } from '@epcbuilder/lib/utils';

export const getCompanyUsers = async ({ id }: { id: number }) => {
  return await api.get(`/company/${id}/users`);
};

export const getCompanyFromUser = async () => {
  return await api.get(`/company/from-user`);
};

export const removeUserFromCompany = async ({ userid, companyid }: { userid: string; companyid: number }) => {
  return await api.post(`/company/${companyid}/remove/${userid}`);
};

export const sendCompanyUserRequest = async ({ email, companyid }: { email: string; companyid: number }) => {
  return await api.post(`/company/${companyid}/request`, { email });
};

export const getJoinRequests = async () => {
  return await api.get(`/company/requests`);
};

export const acceptRequest = async ({ requestId }: { requestId: number }) => {
  return await api.post(`/company/request/${requestId}/accept`);
};

export const rejectRequest = async ({ requestId }: { requestId: number }) => {
  return await api.post(`/company/request/${requestId}/reject`);
};

export const isCompanyAdmin = async ({ companyId }: { companyId: number }) => {
  return await api.post(`/company/${companyId}/is-admin`);
};

export const getPermissions = async ({ companyId }: { companyId: number | undefined }) => {
  return await api.get(`/company/${companyId}/permissions`);
};

export const newCompanyRequest = async ({ companyName, adminEmail }: { companyName: string; adminEmail: string }) => {
  return await api.post(`/company/new`, { companyName, adminEmail });
};

export const getCompanyColour = async ({ companyId, userId }: { companyId: number; userId: string }) => {
  return await api.get(`/company/${companyId}/user-settings/${userId}/colour`);
};

export const updateCompanyColor = async ({
  companyId,
  userId,
  userIconColor,
}: {
  companyId: number;
  userId: string;
  userIconColor: string;
}) => {
  return await api.post(`/company/${companyId}/user-settings/${userId}/colour/${userIconColor.replace('#', '')}`);
};

import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '@epcbuilder/lib/components';
import { format } from 'date-fns';
import { SurveyBooking } from '@/models/job';

export const BookingConfirmationModal = ({
  message = '',
  value,
  onClose,
  callback,
  refetchJobSurvey,
}: {
  message?: string;
  value?: SurveyBooking;
  onClose: () => void;
  callback: () => void;
  refetchJobSurvey: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      callback();
      refetchJobSurvey();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <button
        type="button"
        className="bg-modal-blur fixed inset-0 flex items-center justify-center opacity-20"
        onClick={onClose}
      />
      <div className="bg-modal border-primary relative flex h-screen w-screen flex-col overflow-auto p-8 shadow sm:h-auto sm:max-h-[600px] sm:w-full sm:max-w-[500px] sm:rounded-xl">
        <button
          type="button"
          className="text-primary-lighter absolute right-0 top-0 cursor-pointer p-4"
          onClick={onClose}
        >
          <MdClose size={24} />
        </button>
        <p className="flex justify-center font-bold">Are you sure?</p>
        <p className="mt-4 text-center">
          {message}
          <span className="font-bold">
            {value && format(new Date(value.surveyDate), 'dd/MM/yyyy')}
            {value && (value.bookingSlot === 1 ? ' AM' : ' PM')}
          </span>
          .
        </p>
        <div className="mt-6 flex flex-row justify-between gap-4">
          <Button
            className="border-blue flex h-12 w-full min-w-fit items-center justify-center rounded-[24px] border-2 bg-white px-4 font-bold shadow hover:opacity-80"
            onClick={() => {
              onClose();
              refetchJobSurvey();
            }}
            style="secondary"
          >
            Cancel
          </Button>

          <Button style="custom" loading={loading} onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationModal;

import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Modal } from '@epcbuilder/lib/components';
import { Children } from '@epcbuilder/lib/utils';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import { EligiblityFormData } from '@/models/job';
import { postJobEligibility } from '@/network/jobs';
import { getPostcodeElibilityGroup, getPostcodeLsoa } from '@/network/postcode';
import { doesQualify } from './ecoFlexQualifyingChecker';

const Input = ({ id, check, label, children }: { id: string; check: boolean; label: string; children: Children }) => {
  return (
    <div className="relative">
      <div className="absolute left-3 top-3">{children}</div>
      <label
        htmlFor={id}
        className={`${
          check && 'bg-primary text-light'
        } border-primary flex min-h-10 w-full cursor-pointer items-center gap-2 rounded-[8px] border-2 py-2 pl-10 pr-4`}
      >
        {label}
      </label>
    </div>
  );
};

const flexCriteriaOptions = [
  { label: 'Receive a council tax rebate due to low income', value: 'CouncilTaxRebate' },
  {
    label:
      'A mental health condition, disability, pregnant, aged over 65, a child who has not yet started primary school',
    value: 'NICE',
  },
  { label: 'A child receiving free school meals', value: 'FreeSchoolMeals' },
  {
    label: 'Identified as struggling to pay your energy bills by your provider or Citizens Advice',
    value: 'StrugglingToPay',
  },
  {
    label: 'A cardiovascular condition, respiratory condition, immunosuppression or limited mobility',
    value: 'CriticalIllness',
  },
  { label: 'None of the above', value: 'NoneOfTheAbove' },
];

const benefitOptions = [
  { value: 'childbenefit', label: 'Child Benefit' },
  { value: 'childtaxcredits(ctc)', label: 'Child Tax Credits (CTC)' },
  { value: 'housingbenefit', label: 'Housing Benefit' },
  { value: 'incomesupport(is)', label: 'Income Support (IS)' },
  {
    value: 'incomebasedjobseekersallowance(jsa)',
    label: 'Income-based Jobseekers allowance (JSA)',
  },
  {
    value: 'incomebasedemployment&supportallowance(esa)',
    label: 'Income-based Employment & Support Allowance (ESA)',
  },
  { value: 'pensionguaranteecredit', label: 'Pension Guarantee Credit' },
  { value: 'pensioncreditsavingscredit', label: 'Pension Credit Savings Credit' },
  { value: 'universalcredit(uc)', label: 'Universal Credit (UC)' },
  { value: 'workingtaxcredit(wtc)', label: 'Working Tax Credit (WTC)' },
  { value: 'noneoftheabove', label: 'None of the above' },
];

const eligibilitySchema = yup.object().shape({
  marketingConsent: yup.boolean().oneOf([true], 'Privacy policy must be accepted').required(),
  datamatchConsent: yup.boolean().required(),
  eligible: yup.boolean().required(),
  benefits: yup.array(yup.string().required()).min(1, 'At least 1 option must be selected').required(),
  flexCriteria: yup.array(yup.string().required()).min(1, 'At least 1 option must be selected').required(),
  incomeLess31k: yup.boolean().required('Income must be provided'),
});

const EligibilityModal = ({
  setShowEligibilitySuccessModal,
  setShowEligibilityErrorModal,
  onClose,
}: {
  setShowEligibilitySuccessModal: Dispatch<SetStateAction<boolean>>;
  setShowEligibilityErrorModal: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  const mstGroups = useMemo(() => ['manchester', 'stockport', 'trafford'], []);
  const { property } = usePropertyContext();
  const { job } = useJobContext();
  const [flexEligiblePostcode, setFlexEligiblePostcode] = useState<boolean>(false);
  const [flexLsoaPostcode, setFlexLsoaPostcode] = useState<boolean>(false);
  const [flexMstPostcode, setFlexMstPostcode] = useState<boolean>(false);

  const defaultValues: EligiblityFormData = {
    marketingConsent: false,
    datamatchConsent: false,
    eligible: false,
    flexCriteria: [],
    benefits: [],
    incomeLess31k: undefined,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm<EligiblityFormData>({
    defaultValues,
    resolver: yupResolver<EligiblityFormData>(eligibilitySchema),
    reValidateMode: 'onSubmit',
  });

  const eligible = watch('eligible');
  const incomeLess31k = watch('incomeLess31k');
  const flexCriteria = watch('flexCriteria');
  const benefits = watch('benefits');

  const onSubmit: SubmitHandler<EligiblityFormData> = async (data) => {
    try {
      await postJobEligibility({ id: job?.id, data });
      if (data.eligible) {
        setShowEligibilitySuccessModal(true);
      } else {
        setShowEligibilityErrorModal(true);
      }
      onClose();
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  useEffect(() => {
    const postcode = property?.postcode.replace(' ', '');
    const postcodeCheck = async () => {
      const response = await getPostcodeElibilityGroup({ postcode });
      setFlexEligiblePostcode(response.isEligible);
      setFlexMstPostcode(mstGroups.indexOf(response.group.toLowerCase()) !== -1);
    };

    const lsoaCheck = async () => {
      const response = await getPostcodeLsoa({ postcode });
      setFlexLsoaPostcode(response);
    };

    postcodeCheck();
    lsoaCheck();
  }, [mstGroups, property]);

  useEffect(() => {
    if (eligible) setValue('eligible', false);

    const isEligible = doesQualify({
      flexCriteria,
      benefits,
      incomeLess31k: incomeLess31k === undefined ? false : incomeLess31k,
      isFlexEligiblePostcode: flexEligiblePostcode,
      isFlexLsoaPostcode: flexLsoaPostcode,
      isFlexMstPostcode: flexMstPostcode,
    });

    setValue('eligible', isEligible);
  }, [
    incomeLess31k,
    flexCriteria,
    benefits,
    setValue,
    eligible,
    property,
    flexEligiblePostcode,
    flexLsoaPostcode,
    flexMstPostcode,
  ]);

  const handleFlexCheckboxChange = useCallback(
    (value: string) => {
      if (flexCriteria.includes(value)) {
        setValue(
          'flexCriteria',
          flexCriteria.filter((benefit: string) => benefit !== value)
        );
        return;
      }

      if (value === 'NoneOfTheAbove' || flexCriteria.includes('NoneOfTheAbove')) {
        setValue('flexCriteria', [value]);
        return;
      }

      setValue('flexCriteria', [...flexCriteria, value]);
    },
    [flexCriteria, setValue]
  );

  const handleBenefitsCheckboxChange = useCallback(
    (value: string) => {
      if (benefits.includes(value)) {
        setValue(
          'benefits',
          benefits.filter((benefit: string) => benefit !== value)
        );
        return;
      }

      if (value === 'noneoftheabove' || benefits.includes('noneoftheabove')) {
        setValue('benefits', [value]);
        return;
      }

      setValue('benefits', [...benefits, value]);
    },
    [benefits, setValue]
  );

  return (
    <Modal onClose={onClose}>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <p className="text-center text-xl">Do I qualify for free grants?</p>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <p>1.</p>
            <p>Is your household income below £31,000?</p>
          </div>
          <div className="ml-8 flex flex-row gap-2">
            <Button
              onClick={() => setValue('incomeLess31k', true)}
              style={incomeLess31k === true ? 'primary' : 'secondary'}
            >
              Yes
            </Button>
            <Button
              onClick={() => setValue('incomeLess31k', false)}
              style={incomeLess31k === false ? 'primary' : 'secondary'}
            >
              No
            </Button>
          </div>
          {errors.incomeLess31k?.message && (
            <p className="text-error m-0 ml-8 p-0 text-left text-xs">{errors.incomeLess31k.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <p>2.</p>
            <p>Tick all that apply to you or someone in your household:</p>
          </div>
          <div className="ml-8 flex flex-col gap-2">
            {flexCriteriaOptions.map((option, index) => (
              <Input
                key={index}
                id={`flexCriteria-${index}`}
                check={flexCriteria?.includes(option.value)}
                label={option.label}
              >
                <input
                  {...register('flexCriteria')}
                  type="checkbox"
                  id={`flexCriteria-${index}`}
                  name="flexCriteria"
                  value={option.value}
                  checked={flexCriteria?.includes(option.value)}
                  onChange={() => handleFlexCheckboxChange(option.value)}
                />
              </Input>
            ))}
          </div>
          {errors.flexCriteria?.message && (
            <p className="text-error m-0 ml-8 p-0 text-left text-xs">{errors.flexCriteria.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <p>3.</p>
            <p>Do you, or anyone in the household, receive any of the following benefits?</p>
          </div>
          <div className="ml-8 flex flex-col gap-2">
            {benefitOptions.map((option, index) => (
              <Input
                key={option.label}
                id={`benefit-${index}`}
                check={benefits?.includes(option.value)}
                label={option.label}
              >
                <input
                  {...register('benefits')}
                  type="checkbox"
                  id={`benefit-${index}`}
                  name="benefits"
                  value={option.value}
                  checked={benefits?.includes(option.value)}
                  onChange={() => handleBenefitsCheckboxChange(option.value)}
                />
              </Input>
            ))}
          </div>
          {errors.benefits?.message && (
            <p className="text-error m-0 ml-8 p-0 text-left text-xs">{errors.benefits.message}</p>
          )}
        </div>
        <div className="ml-8 flex flex-col gap-2">
          <p className="text-left text-base">
            I understand that by submitting this form, I fully consent to the processing of my personal data and to
            being contacted by email and/or phone in order to process my ECO funding.
          </p>
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <input
                {...register('marketingConsent')}
                type="checkbox"
                id="marketingConsent"
                name="marketingConsent"
                className="cursor-pointer"
              />
              <label htmlFor="marketingConsent" className="cursor-pointer">
                I agree
              </label>
            </div>
            {errors.marketingConsent?.message && (
              <p className="text-error m-0 p-0 text-left text-xs">{errors.marketingConsent.message}</p>
            )}
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center">
          <div className="w-40">
            <Button id="eligiblity-submit" loading={isSubmitting} type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EligibilityModal;

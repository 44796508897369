import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import { Properties } from '@/components/properties';
import withAuth from '@/hoc/withAuth';

const PropertiesPage = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Properties">
        <Properties />
      </Layout>
    </>
  );
};

export default withAuth(PropertiesPage);

import React, { useEffect, useMemo, useState } from 'react';
import LogoDark from '../assets/images/epc-builder-logo-dark.png';
import LogoDarkColor from '../assets/images/epc-builder-logo-dark-color.png';
import LogoLight from '../assets/images/epc-builder-logo-light.png';
import LogoLightColor from '../assets/images/epc-builder-logo-light-color.png';
import { useTheme } from '../context/themeContext';

const Logo = ({
  type = 'mono',
  className,
  href = '/properties',
}: {
  type?: 'mono' | 'color';
  className?: string;
  href?: string;
}) => {
  const [mounted, setMounted] = useState(false);
  const { theme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  const colorLogo = useMemo(() => (theme === 'dark' ? LogoLightColor : LogoDarkColor), [theme]);

  const monoLogo = useMemo(() => (theme === 'dark' ? LogoLight : LogoDark), [theme]);

  if (!mounted) {
    return;
  }

  return (
    <div className="relative flex size-full">
      <a href={href}>
        <img id="logo" src={type === 'mono' ? monoLogo : colorLogo} alt="EPC Builder" className={className} />
      </a>
    </div>
  );
};

export default Logo;

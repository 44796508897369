import React, { createContext, useContext } from 'react';
import { Property } from '@epcbuilder/lib/models/properties';
import { Children } from '@epcbuilder/lib/utils';
import { KeyedMutator } from 'swr';
import useProperty from '@/hooks/properties/useProperty';

type PropertyContextProps = {
  property: Property | undefined;
  error: unknown;
  mutate: KeyedMutator<Property>;
  isLoading: boolean;
};

const PropertyContext = createContext<PropertyContextProps | undefined>(undefined);

const PropertyProvider = ({ id, children }: { id?: string; children: Children }) => {
  const { property, error, mutate, isLoading } = useProperty({ id });

  const contextValue: PropertyContextProps = {
    property,
    error,
    mutate,
    isLoading,
  };

  return <PropertyContext.Provider value={contextValue}>{children}</PropertyContext.Provider>;
};

const usePropertyContext = () => {
  const context = useContext(PropertyContext);
  if (!context) {
    throw new Error('usePropertyContext must be used within a PropertyProvider');
  }
  return context;
};

export { PropertyProvider, usePropertyContext };

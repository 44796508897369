import { PropertyRating } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getPropertyRatings } from '@/network/properties';

const usePropertyRatings = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<PropertyRating>(
    id ? `property-ratings-data-${id}` : null,
    async () => (id ? await getPropertyRatings({ id }) : null)
  );

  return {
    propertyRatings: data,
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyRatings;

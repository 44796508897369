import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';
import { Property } from '@/components/property';
import withAuth from '@/hoc/withAuth';
import usePropertyJob from '@/hooks/properties/usePropertyJob';

const PropertyPage = () => {
  const params = useParams();

  const { job, error: jobError } = usePropertyJob({ id: params.id });

  const renderJob = useCallback(() => {
    if (
      jobError &&
      (jobError.detail === 'You do not have access to this job' || jobError.detail === 'Job could not be found')
    ) {
      return (
        <FullPage>
          <div>You do not have access to this job</div>
        </FullPage>
      );
    }

    if (!job) {
      return (
        <FullPage>
          <Loading />
        </FullPage>
      );
    }

    return <Property jobId={job.id} propertyId={job.propertyId} />;
  }, [job, jobError]);

  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Property">
        <div className="flex flex-col p-4 sm:p-8">
          <Link to="/properties" className="flex flex-row items-center gap-2">
            <MdKeyboardArrowLeft size={20} />
            <p>Back</p>
          </Link>
          {renderJob()}
        </div>
      </Layout>
    </>
  );
};

export default withAuth(PropertyPage);
